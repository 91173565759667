import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsTag } from "../interfaces/goods-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsTagList = (param?: Partial<GoodsTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsTagList', param], () => client('goodsTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditGoodsTag = () => { 
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsTag>) => client(`goodsTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsTagList')
        }
    )
}

export const useAddGoodsTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsTag>) => client(`goodsTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsTagList')
        }
    )
}

export const useDeleteGoodsTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsTagId }: { goodsTagId: number}) =>
            client(`goodsTag/${goodsTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsTagList')
        }
    );
};

export const useGoodsTag = (goodsTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsTag', {goodsTagId}],
        () => client(`goodsTag/${goodsTagId}`),
        {
            enabled: Boolean(goodsTagId)
        }
    )
}

/**查询全部 */
export const useGoodsTagListAll = (param?: Partial<GoodsTag>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsTagListAll", param], () =>
        client("goodsTag/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};