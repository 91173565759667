import { Button, Col, Divider, Form, Input, message, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGoodsTag, useEditGoodsTag } from "../../services/goods-tag-service";
import { useDocumentTitle } from "../../utils"

export const GoodsTagUpdatePage = () => {
    //标题
    useDocumentTitle('编辑商品标签', true)
    const { pathname } = useLocation();
    const goodsTagId = pathname.match(/goods\/manage\/goods-tag\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingGoodsTag, isLoading } = useGoodsTag(
        Number(goodsTagId)
    );  
    useEffect(() => {
        form.setFieldsValue(editingGoodsTag?.data)
    }, [editingGoodsTag, form]);
    //编辑
    const useMutateEditGoodsTag = useEditGoodsTag;
    const {mutateAsync, error } =  useMutateEditGoodsTag();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingGoodsTag?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/goods/manage/goods-tag/list"; 
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/goods/manage/goods-tag/list"}>
                            <Button type="primary">标签列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"标签名称"}
                        name={"goodsTagName"}
                        rules={[{ required: true, message: "标签名称必填" }]}
                    >
                        <Input placeholder={"请输入标签名称"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

