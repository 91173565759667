import { Button, Col, message, Modal, Row, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useStoreGoodsClassList,
  useDeleteStoreGoodsClass,
} from "../../services/store-goods-class-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreGoodsClassSearchParams } from "./util";

export const StoreGoodsClassListPage = () => {
  useDocumentTitle("分类列表", true);
  const [param, setParam] = useStoreGoodsClassSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useStoreGoodsClassList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreGoodsClass = useDeleteStoreGoodsClass;
  const { mutateAsync } = useMutateDeleteStoreGoodsClass();
  const confirmDeleteStoreGoodsClass = (storeGoodsClassId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storeGoodsClassId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/goods/class/add"}>
            <Button type="primary">新建分类</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索分类名称"
            style={{ width: 200 }}
            value={param.storeGoodsClassName}
            onChange={(evt) =>
              setParam({
                ...param,
                storeGoodsClassName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                storeGoodsClassName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storegoodsClassId}
        pagination={paginationProps}
        columns={[
          {
            title: "分类ID",
            dataIndex: "storeGoodsClassId",
            key: "storeGoodsClassId",
            width: 150,
          },
          {
            title: "分类名称",
            dataIndex: "storeGoodsClassName",
            key: "storeGoodsClassName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeGoodsClass) => (
              <Space size="middle">
                <Link
                  to={
                    "/goods/class/update/" + storeGoodsClass.storeGoodsClassId
                  }
                >
                  编辑
                </Link>
                <a
                  onClick={() =>
                    confirmDeleteStoreGoodsClass(
                      storeGoodsClass.storeGoodsClassId
                    )
                  }
                >
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
