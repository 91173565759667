import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreSettleAccount } from "../interfaces/store-settle-account";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreSettleAccountList = (param?: Partial<StoreSettleAccount>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeSettleAccountList', param], () => client('storeSettleAccount', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreSettleAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreSettleAccount>) => client(`storeSettleAccount`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeSettleAccountList')
        }
    )
}

export const useAddStoreSettleAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreSettleAccount>) => client(`storeSettleAccount`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeSettleAccountList')
        }
    )
}

export const useDeleteStoreSettleAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeSettleAccountId }: { storeSettleAccountId: number}) =>
            client(`storeSettleAccount/${storeSettleAccountId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeSettleAccountList')
        }
    );
};

export const useStoreSettleAccount = (storeSettleAccountId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeSettleAccount', {storeSettleAccountId}],
        () => client(`storeSettleAccount/${storeSettleAccountId}`),
        {
            enabled: Boolean(storeSettleAccountId)
        }
    )
}

export const useStoreSettleAccountListAll = () => {
    const client = useHttp();
    return useQuery<any>(['storeSettleAccountListAll', {}], () => client('storeSettleAccount/listAll'))
}


