import { useQuery } from "react-query";
import { useHttp } from "../utils/http";

export const useStoreDashboard = () => {
    const client = useHttp()
    return useQuery<any>(
        ['storeDashboard', {}],
        () => client(`storeDashboard`),
        {}
    )
}