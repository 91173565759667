import { Button, Col, Dropdown, Menu, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useCouponList, useDeleteCoupon } from "../../services/coupon-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCouponSearchParams } from "./util"
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'

export const CouponListPage = () => {
    useDocumentTitle('优惠券列表', true)
    const [param, setParam] = useCouponSearchParams()
    const {isLoading, error, totalElements, data: list} = useCouponList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteCoupon = useDeleteCoupon;
    const { mutateAsync } = useMutateDeleteCoupon();
    const confirmDeleteCoupon = (couponId: number) => {
        Modal.confirm({
            title: "确定失效这条数据吗?",
            content: "点击确定失效",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ couponId }).then(() => {
                        message.info('失效成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //编辑菜单
    const operateMenu:any = (record:any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/application/coupon-manage/coupon/goods/list/" + record.couponId}>
                        已选商品列表
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={"/application/coupon-manage/coupon/goods/listNo/" + record.couponId}>
                        未选商品列表
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }           
    //编辑菜单
    const editMenu:any = (record:any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/application/coupon-manage/coupon/update/" + record.couponId}>
                        编辑
                    </Link>
                </Menu.Item>
                {record.couponStatus === true ? <Menu.Item>
                    <a onClick={() => confirmDeleteCoupon(record.couponId)}>
                        失效
                    </a>
                </Menu.Item>:''}
                
                <Menu.Item>
                    <Link to={"/application/coupon-manage/coupon/member/list/" + record.couponId}>
                        使用记录
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/application/coupon-manage/coupon/add"}>
                        <Button type="primary">新建优惠券</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索优惠券标题" 
                        style={{ width: 200 }}
                        value={param.couponTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            couponTitle: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            couponTitle: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.couponId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '优惠券ID',
                    dataIndex: 'couponId',
                    key: 'couponId',
                    width: 100,
                },
                {
                    title: '优惠券标题',
                    dataIndex: 'couponTitle',
                    key: 'couponTitle',
                },
                { 
                    title: '优惠金额', 
                    dataIndex: 'couponPrice', 
                    key: 'couponPrice' 
                },
                { 
                    title: '已领取', 
                    dataIndex: 'couponReceive', 
                    key: 'couponReceive' 
                },
                { 
                    title: '剩余', 
                    dataIndex: 'couponStorage', 
                    key: 'couponStorage' 
                },
                { 
                    title: '已使用', 
                    dataIndex: 'couponUsage', 
                    key: 'couponUsage' 
                },
                { 
                    title: '优惠券状态', 
                    dataIndex: 'couponStatus', 
                    key: 'couponStatus',
                    render(value, coupon) {
                        return <span>
                            {coupon.couponStatus == true ? '正常':'失效'}<br/>
                        </span>
                    },
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, coupon) => (
                        <Space size="middle">
                            {coupon.couponLimitGoods === true ? <Dropdown overlay={operateMenu.bind(this,coupon)}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    商品 <DownOutlined />
                                </a>
                            </Dropdown> : ''}
                            
                            <Dropdown overlay={editMenu.bind(this,coupon)}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    操作 <DownOutlined />
                                </a>
                            </Dropdown>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}