import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Upload,
  Menu,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useGrouponAdv,
  useEditGrouponAdv,
} from "../../services/groupon-adv-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const GrouponAdvUpdatePage = () => {
  //标题
  useDocumentTitle("编辑广告", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const grouponAdvId = pathname.match(
    /application\/groupon-manage\/groupon\/adv\/update\/(\d+)/
  )?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备跳转链接
  const [propertyShow, setPropertyShow] = useState(false);
  const [propertyName, setPropertyName] = useState("参数");
  const { data: pageUrlList } = usePageUrlList();
  const options = pageUrlList?.map((item: any, value: any) => (
    <Option value={item.pageUrlId} key={item.pageUrlId}>
      {item.pageUrlName}
    </Option>
  ));
  //option选中与非选中
  const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
  function optionHandleChange(pageUrlId: number) {
    mutateAsyncPageUrl({ pageUrlId }).then((data) => {
      const pageUrlFlag = data.data.pageUrlFlag;
      const pageUrlPropertyName = data.data.pageUrlPropertyName;
      setPropertyShow(pageUrlFlag);
      setPropertyName(pageUrlPropertyName);
    });
  }
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingGrouponAdv, isLoading } = useGrouponAdv(
    Number(grouponAdvId)
  );
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    form.setFieldsValue(editingGrouponAdv?.data);
    const picUrl =
      imgUrl +editingGrouponAdv?.data?.grouponAdvPic;
    setImageUrl(picUrl);
    const pageUrlPropertyValue = editingGrouponAdv?.data?.pageUrlPropertyValue;
    if (pageUrlPropertyValue != null) {
      setPropertyShow(true);
    } else {
      setPropertyShow(false);
    }
  }, [editingGrouponAdv, form]);
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        grouponAdvPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //编辑
  const useMutateEditGrouponAdv = useEditGrouponAdv;
  const { mutateAsync, error } = useMutateEditGrouponAdv();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingGrouponAdv?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/application/groupon-manage/groupon/adv/list";
    });
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <Menu
          style={{ marginBottom: 24 }}
          mode="horizontal"
          selectedKeys={["grouponAdv"]}
        >
          <Menu.Item key="groupon">
            <Link to={"/application/groupon-manage/groupon/list"}>拼团管理</Link>
          </Menu.Item>
          <Menu.Item key="grouponAdv">
            <Link to={"/application/groupon-manage/groupon/adv/list"}>拼团广告</Link>
          </Menu.Item>
        </Menu>
        <Row>
          <Col span={12}>
            <Link to={"/application/groupon-manage/groupon/adv/list"}>
              <Button type="primary">广告列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"广告标题"}
            name={"grouponAdvTitle"}
            rules={[{ required: true, message: "广告标题必填" }]}
          >
            <Input placeholder={"请输入广告标题"} />
          </Form.Item>

          <Form.Item
            name={"uploadPic"}
            label={"广告图片"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "广告图片必传" }]}
            style={{ textAlign: "left" }}
            initialValue={[
              {
                uid: "",
                lastModified: "",
                lastModifiedDate: "",
                name: "",
                size: "",
                type: "",
                percent: "",
                originFileObj: {
                  uid: "",
                },
                status: "done",
                response: {
                  status: 0,
                  data: {
                    relativePath: "",
                    realPath: "",
                  },
                },
                xhr: {},
              },
            ]}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            hidden
            label={"广告图片"}
            name={"grouponAdvPic"}
            rules={[{ required: true, message: "广告图片必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"跳转链接"}
            name={"pageUrlId"}
            rules={[{ required: true, message: "跳转链接必选" }]}
          >
            <Select
              showSearch
              placeholder="请选择跳转链接"
              optionFilterProp="children"
              style={{ textAlign: "left" }}
              onChange={optionHandleChange}
            >
              {options}
            </Select>
          </Form.Item>

          {propertyShow ? (
            <Form.Item
              label={propertyName}
              name={"pageUrlPropertyValue"}
              rules={[{ required: true, message: "链接参数必传" }]}
            >
              <Input placeholder={"请输入链接参数"} />
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item
            label={"广告排序"}
            name={"grouponAdvSort"}
            rules={[{ required: true, message: "广告排序必填" }]}
          >
            <Input placeholder={"请输入广告排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"grouponAdvShow"}
            rules={[{ required: true, message: "广告显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio defaultChecked value={true}>
                显示
              </Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
