import { Divider, Upload, Row, Col, Button, Input, Form, Radio, message, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddCouponPackage } from '../../services/coupon-package-service';
import { useCouponListAll } from "../../services/coupon-service";
import { useForm } from 'antd/lib/form/Form';
import { getToken } from '../../utils/auth-provider';
const { Option } = Select;

export const CouponPackageAddPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [form] = useForm();
    //优惠券
    const { data: couponList } = useCouponListAll();
    const options = couponList?.map((item: any, value: any) =>
        <Option value={item.couponId} key={item.couponId}>
            {item.couponTitle}
        </Option>
    );
    const [couponIds, setCouponIds] = useState([]);
    function handleChangeOption(value: any) {
        setCouponIds(value);
    }
    //图片上传
    const [uploadLoading, setUploadLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                couponPackagePic: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //领取限制
    const [limit, setLimit] = useState<any>(false);
    const handleLimit = (e:any) => {
        setLimit(e.target.value);
    };
    //保存
    const useMutateCouponPackage = useAddCouponPackage;
    const { mutateAsync, error } = useMutateCouponPackage();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, couponIds }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/application/couponPackage/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/application/couponPackage/list"}>
                        <Button type="primary">礼包列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    couponPackageReceiveLimit: true,
                    couponPackageDefectCondition: 1
                }}
            >
                <Form.Item
                    label={"礼包名称"}
                    name={"couponPackageTitle"}
                    rules={[{ required: true, message: "礼包名称必填" }]}
                >
                    <Input placeholder={"请输入礼包名称"} />
                </Form.Item>

                <Form.Item
                    label={"礼包内容"}
                    name={"couponIds"}
                    rules={[{ required: true, message: "礼包内容必选" }]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        placeholder="请选择礼包内容"
                        optionFilterProp="children"
                        style={{textAlign:'left'}}
                        onChange={handleChangeOption}
                    >
                        {options}
                    </Select>
                </Form.Item>

                <Form.Item
                    extra="修改礼包总量时只能增加不能减少，请谨慎设置"
                    label={"礼包发放上限"}
                    name={"couponPackageNum"}
                >
                    <Input placeholder={"请输入礼包发放上限"} addonAfter="份" />
                </Form.Item>

                <Form.Item
                    name={"uploadPic"}
                    label={"礼包背景图"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: "礼包背景图必传" }]}
                    style={{ textAlign:'left' }}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        headers={{ "Authorization": "Bearer " + token }}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"礼包背景图"}
                    name={"couponPackagePic"}
                    rules={[{ required: true, message: "礼包背景图必传" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"领取限制"}
                    name={"couponPackageReceiveLimit"}
                    rules={[{ required: true, message: "领取限制必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group onChange={handleLimit}>
                        <Radio defaultChecked value={true}>限制</Radio>
                        <Radio value={false}>不限制</Radio>
                    </Radio.Group>
                </Form.Item>

                {limit == true? <Form.Item
                    label={"最多可领取数量"}
                    name={"couponPackageMaxReceive"}
                    rules={[{ required: true, message: "最多可领取数量必填" }]}
                >
                    <Input placeholder={"请输入最多可领取数量"} />
                </Form.Item> : ''}

                <Form.Item
                    label={"礼包失效条件"}
                    name={"couponPackageDefectCondition"}
                    rules={[{ required: true, message: "礼包失效条件必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={1}>任意一张优惠券库存不足或失效时礼包失效</Radio>
                        <Radio value={2}>所有优惠券库存不足或失效时时礼包失效</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}