import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreFloor } from "../interfaces/store-floor";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreFloorList = (param?: Partial<StoreFloor>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeFloorList', param], () => client('storeFloor', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreFloor>) => client(`storeFloor`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFloorList')
        }
    )
}

export const useAddStoreFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreFloor>) => client(`storeFloor`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFloorList')
        }
    )
}

export const useDeleteStoreFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeFloorId }: { storeFloorId: number}) =>
            client(`storeFloor/${storeFloorId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFloorList')
        }
    );
};

export const useStoreFloor = (storeFloorId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeFloor', {storeFloorId}],
        () => client(`storeFloor/${storeFloorId}`),
        {
            enabled: Boolean(storeFloorId)
        }
    )
}