import { Table, Row, Col, Statistic } from 'antd';
import { useStoreMoneyLogList } from '../../services/store-money-log-service';
import { useStore } from '../../services/store-service';
import { useDebounce } from '../../utils';
import { useStoreMoneyLogSearchParams } from './util';
import dayjs from 'dayjs';

export const StoreMoneyLogListPage = () => {
    //参数    
    const [param, setParam] = useStoreMoneyLogSearchParams();
    const { isLoading, error, totalElements, data: list } = useStoreMoneyLogList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //详情
    const { data: store } = useStore(); 
    console.log(store?.data)
    return (
        <div>
            <Row style={{backgroundColor: "#f7f8fa", fontSize:14, fontWeight:500, marginBottom: 10, padding: 10}}>
                店铺余额
            </Row>
            <Row style={{height:100, backgroundColor: "#f7f8fa", padding: 20, marginBottom: 10}}>
                <Col span={8}> 
                    <Statistic title="可用店铺余额(元)" value={store?.data?.storeMoney} />
                </Col>
                <Col span={8}>
                    <Statistic title="已提现金额" value={store?.data?.storeMoneyMinus} />
                </Col>
                <Col span={8}>
                    <Statistic title="店铺累计余额" value={store?.data?.storeMoneyCount} />
                </Col>
            </Row>
            <Row style={{backgroundColor: "#f7f8fa", fontSize:14, fontWeight:500, marginBottom: 10, padding: 10}}>
                余额明细
            </Row>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.storeMoneyLogId } 
                    columns = {[
                        {
                            title: '时间',
                            dataIndex: 'storeMoneyLogSn',
                            key: 'storeMoneyLogSn',
                            render(value, storeMoneyLog) {
                                return <span>
                                    {storeMoneyLog.storeMoneyLogTime ? dayjs(storeMoneyLog.storeMoneyLogTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '名称',
                            dataIndex: 'storeMoneyLogChangeReason',
                            key: 'storeMoneyLogChangeReason',
                        },
                        {
                            title: '订单编号|支付单号',
                            dataIndex: 'storeMoneyLogSn',
                            key: 'storeMoneyLogSn',
                            render(value, storeMoneyLog) {
                                return <span>
                                    {storeMoneyLog.storeMoneyLogSn}
                                </span>
                            },
                        },
                        
                        {
                            title: '金额',
                            dataIndex: 'storeMoneyLogEndMoney',
                            key: 'storeMoneyLogEndMoney',
                            render(value, storeMoneyLog) {
                                return <span>
                                    {storeMoneyLog.storeMoneyLogOperation == 'add' ? '+':'-'} {storeMoneyLog.storeMoneyLogChangeMoney}
                                </span>
                            },
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
        </div>
    )
}