import { Divider, Row, Col, Button, Input, Form, DatePicker, message, Modal, Table, Space, Image, Radio, Spin, Menu, Select } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddPointsGoods } from '../../services/points-goods-service';
import { useSingleSpecificationGoodsList, useGoodsMutation } from '../../services/goods-service';
import { usePointsGoodsClassListAll } from "../../services/points-goods-class-service";
import { useGoodsSearchParams } from '../goods/util';
import { useForm } from 'antd/lib/form/Form';
import { useDebounce, useDocumentTitle } from '../../utils';
import { useAreaListStore } from '../../services/area-service';
const { RangePicker } = DatePicker;
const { Option } = Select;

export const PointsGoodsAddPage = () => {
    useDocumentTitle('新建商品', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备分类
    const { data: pointsGoodsClassList } = usePointsGoodsClassListAll();
    const pointsGoodsClassOptions = pointsGoodsClassList?.data?.map((item: any, value: any) => (
        <Option value={item.pointsGoodsClassId} key={item.pointsGoodsClassId}>
            {item.pointsGoodsClassName}
        </Option>
    ));
    const [submitLoading, setSubmitLoading] = useState(false);
    //商品
    const [param, setParam] = useGoodsSearchParams();
    const { isLoading, totalElements, data: list } = useSingleSpecificationGoodsList(useDebounce(param, 200));
    const [defaultCurrent, setDefaultCurrent] = useState<number>(1);
    //分页
    const paginationProps = {
        defaultCurrent: defaultCurrent,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>{
            setParam({
                ...param,
                pageNum: (current - 1),
            });
            setDefaultCurrent(current);
        }
    }

    //商品弹框
    const [goodsId, setGoodsId] = useState<number>();
    const [goods, setGoods] = useState<any>();
    const useMutateGoods = useGoodsMutation;
    const { mutateAsync: mutateAsyncGoods } = useMutateGoods();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [submitGoodsLoading, setSubmitGoodsLoading] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const onFinishGoods = (values: any) => {
        setParam({
            ...param,
            goodsName: values.goodsName,
            pageNum: null
        })
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    }
    const [val, setVal] = useState([
        {
            "goodsSkuId": 0,
            "goodsSkuName": "",
            "goodsSkuPic": "",
            "goodsSkuPrice": 0,
            "goodsSkuOriginalPrice": 0,
            "goodsSkuCostPrice": 0,
            "goodsSkuStorage": 0,
            "goodsSkuWarningStorage": 0,
            "goodsSkuMinNum": 0,
            "goodsSkuSaleNum": 0,
            "goodsSkuSerial": null,
            "goodsId": 0,
            "shopId": 0
        }
    ]);
    //保存
    const useMutateAddPointsGoods = useAddPointsGoods;
    const { mutateAsync, error } = useMutateAddPointsGoods();
    //表单
    const [form] = useForm();
    const [purchaseLimit, setPurchaseLimit] = useState<any>(false);
    const handlePurchaseLimit = (e: any) => {
        setPurchaseLimit(e.target.value);
    };
      //准备区域数据
  const { data: areaList } = useAreaListStore();
  const areaListOptions = areaList?.data?.map((item: any, value: any) => (
    <Select.Option value={item.areaId} key={item.areaId}>
      {item.areaName}
    </Select.Option>
  ));
    //活动时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [pointsGoodsStartTimeStr, setPointsGoodsStartTimeStr] = useState();
    const [pointsGoodsEndTimeStr, setPointsGoodsEndTimeStr] = useState();
    const handleSelectTime = (value: any, dateString: any) => {
        setPointsGoodsStartTimeStr(dateString[0]);
        setPointsGoodsEndTimeStr(dateString[1]);
    }
    const selectGoods = (goodsId: number) => {
        mutateAsyncGoods({ goodsId }).then((data) => {
            if (data.data.goodsSpecOpen) {
                setVal(data.data.goodsSkuList);
            }
            setGoods(data.data);
            setGoodsId(goodsId)
        });
        setIsModalVisible(false);
    };
    const onFinish = (values: any) => {
        if (goods?.goodsId > 0) {
            setSubmitLoading(true);
            const pointsGoodsGoodsSkuList = values?.pointsGoodsGoodsSkuList
            mutateAsync({ ...values, goodsId, pointsGoodsGoodsSkuList, pointsGoodsStartTimeStr, pointsGoodsEndTimeStr }).then((data) => {
                if (data.status === 0) {
                    window.location.href = "/#/application/points-manage/goods/list";
                } else {
                    setSubmitLoading(false);
                    message.error(data.msg);
                }
            });
        } else {
            setSubmitLoading(false);
            message.error('请选择商品');
        }
    };
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['pointsGoods']}>
                <Menu.Item key="pointsGoods">
                    <Link to={"/application/points-manage/goods/list"}>
                        商品管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsGoodsClass">
                    <Link to={"/application/points-manage/goodsClass/list"}>
                        分类管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsAdv">
                    <Link to={"/application/points-manage/adv/list"}>
                        广告管理
                    </Link>
                </Menu.Item>
            </Menu>
            <Spin spinning={isLoading}>
                <Modal
                    confirmLoading={submitGoodsLoading}
                    centered
                    bodyStyle={{ height: '600px', overflowY: 'auto' }}
                    destroyOnClose={true}
                    width={888}
                    title="选择商品"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}>
                    <Row style={{ marginBottom: 24 }}>
                        <Form onFinish={onFinishGoods}>
                            <Form.Item
                                name="goodsName"
                                label="商品名称"
                            >
                                <Input placeholder="输入商品名称" />
                            </Form.Item>
                            <Form.Item>
                                <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Form.Item>
                        </Form>
                    </Row>
                    <Table
                        loading={isLoading}
                        pagination={paginationProps}
                        rowKey={columns => columns.goodsId}
                        columns={[
                            {
                                title: '商品名称',
                                width: 300,
                                dataIndex: 'goodsName',
                                key: 'goodsName',
                                fixed: 'left',
                                render: (value, goods) => (
                                    <Space size="middle">
                                        <Row>
                                            <Col span={6}>
                                                <Image
                                                    width="100%"
                                                    src={imgUrl  + goods.goodsPic}
                                                />
                                            </Col>
                                            <Col span={18} style={{ paddingLeft: 10 }}>
                                                {goods.goodsName}
                                            </Col>
                                        </Row>
                                    </Space>
                                ),
                            },
                            {
                                title: '价格（元）',
                                dataIndex: 'goodsPrice',
                                key: 'goodsPrice'
                            },
                            {
                                title: '库存',
                                dataIndex: 'goodsStorage',
                                key: 'goodsStorage'
                            },
                            {
                                title: '销量',
                                dataIndex: 'goodsSaleNum',
                                key: 'goodsSaleNum'
                            },
                            {
                                title: '操作',
                                key: 'operation',
                                fixed: 'right',
                                width: 100,
                                render: (value, goods) => (
                                    <Space size="middle">
                                        <a onClick={() => selectGoods(goods.goodsId)}>
                                            选择
                                        </a>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={list || []}
                    />
                </Modal>
            </Spin>
            <Row>
                <Col span={12}>
                    <Link to={"/application/points-manage/goods/list"}>
                        <Button type="primary">商品列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        pointsGoodsPurchaseLimit: false,
                        pointsGoodsRecommend: false,
                        pointsGoodsSort: 0,
                        pointsGoodsPayType: 1
                    }}
                >
                    <Form.Item
                        label={"活动名称"}
                        name={"pointsGoodsTitle"}
                        rules={[{ required: true, message: "活动名称必填" }]}
                    >
                        <Input placeholder={"请输入活动名称"} />
                    </Form.Item>

                    <Form.Item
                        name="pointsGoodsTimeRange"
                        label="活动时间"
                        {...rangeConfig}
                        rules={[{ required: true, message: "活动时间必选" }]}
                    >
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label={"支付类型"}
                        name={"pointsGoodsPayType"}
                        rules={[{ required: true, message: "支付类型必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={1}>纯积分</Radio>
                            <Radio value={2}>积分+现金</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"商品分类"}
                        name={"pointsGoodsClassId"}
                        rules={[{ required: true, message: "商品分类必选" }]}
                        >
                        <Select
                            showSearch
                            placeholder="请选择商品分类"
                            optionFilterProp="children"
                            style={{ textAlign: "left" }}
                        >
                            {pointsGoodsClassOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="pointsGoods"
                        label="活动商品"
                    >
                        <Row>
                            <a onClick={showModal}>
                                选择商品
                            </a>
                        </Row>
                    </Form.Item>

                    {goods ?
                        <div>
                            <Form.Item
                                name="pointsGoods"
                                label="已选商品"
                            >
                                <Row>
                                    <Col span={6}>
                                        <Image
                                            width="100%"
                                            src={imgUrl  + goods?.goodsPic}
                                        />
                                    </Col>
                                    <Col span={18} style={{ paddingLeft: 10 }}>
                                        <Row>
                                            <Col span={24} style={{ marginBottom: 5 }}>
                                                {goods?.goodsName}
                                            </Col>
                                            {goods?.goodsSpecOpen === false ?
                                                <div>
                                                    <Col span={24} style={{ marginBottom: 5 }}>
                                                        规格：{goods?.goodsSpecName}
                                                    </Col>
                                                </div> : ''
                                            }

                                        </Row>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div> : ''}

                    {goods?.goodsSpecOpen === false ?
                        <div>
                            <Form.Item
                                label="积分设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        积分
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left' }}>
                                        库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        <Input readOnly value={goods?.goodsSpecName} placeholder="请输入规格名称" />
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        <Form.Item
                                            name={"pointsGoodsPrice"}
                                            rules={[{ required: true, message: "积分必填" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        <Form.Item
                                            name={"pointsGoodsMoney"}
                                            rules={[{ required: true, message: "价格必填" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left' }}>
                                        <Form.Item
                                            name={"pointsGoodsStorage"}
                                            rules={[{ required: true, message: "库存必填" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div> : ""}

                    {goods?.goodsSpecOpen === true ?
                        <div>
                            <Form.Item
                                label="积分设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        积分
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left' }}>
                                        库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Form.List initialValue={val} name="pointsGoodsGoodsSkuList">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Space
                                                        key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Space>
                                                            <Form.Item hidden
                                                                name={[field.name, 'goodsSkuId']}
                                                                rules={[{ required: true, message: '规格ID必填' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'goodsSkuName']}
                                                                rules={[{ required: true, message: '规格名称必填' }]}
                                                            >
                                                                <Input readOnly />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsPrice']}
                                                                rules={[{ required: true, message: '积分必填' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsMoney']}
                                                                rules={[{ required: true, message: '价格必填' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsStorage']}
                                                                rules={[{ required: true, message: '库存必填' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Space>
                                                    </Space>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                            </Form.Item>
                        </div> : ""}

                    <Form.Item
                        label={"每人限购"}
                        name={"pointsGoodsPurchaseLimit"}
                        rules={[{ required: true, message: "每人限购必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group onChange={handlePurchaseLimit}>
                            <Radio defaultChecked value={true}>开启限购</Radio>
                            <Radio value={false}>关闭限购</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {purchaseLimit === true ?
                        <div>
                            <Form.Item
                                label={"限购数量"}
                                name={"pointsGoodsPurchaseLimitNum"}
                                rules={[{ required: true, message: "限购数量必填" }]}
                                style={{ textAlign: 'left' }}
                            >
                                <Input placeholder={"请输入限购数量"} />
                            </Form.Item>
                        </div> : ""}

                    <Form.Item
                        label={"排序"}
                        name={"pointsGoodsSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"是否推荐"}
                        name={"pointsGoodsRecommend"}
                        rules={[{ required: true, message: "是否推荐必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>推荐</Radio>
                            <Radio defaultChecked value={false}>不推荐</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={"显示区域"}
                        name={"areaId"}
                        rules={[{ required: true, message: "显示区域必选" }]}
                    >
                        <Select
                        showSearch
                        placeholder="请选择显示区域"
                        optionFilterProp="children"
                        style={{ textAlign: "left" }}
                        >
                        <option value={0}>全部</option>
                        {areaListOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}