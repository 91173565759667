import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useStoreVideoAlbumList, useDeleteStoreVideoAlbum } from "../../services/store-video-album-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreVideoAlbumSearchParams } from "./util";

export const StoreVideoAlbumListPage = () => {
  useDocumentTitle("专辑列表", true);
  const [param, setParam] = useStoreVideoAlbumSearchParams();
  const { isLoading, error, totalElements, data: list } = useStoreVideoAlbumList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreVideoAlbum = useDeleteStoreVideoAlbum;
  const { mutateAsync } = useMutateDeleteStoreVideoAlbum();
  const confirmDeleteStoreVideoAlbum = (storeVideoAlbumId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storeVideoAlbumId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storeVideoAlbum"]}
      >
        <Menu.Item key="storeVideo">
          <Link to={"/store/video/list"}>视频管理</Link>
        </Menu.Item>
        <Menu.Item key="storeVideoAlbum">
          <Link to={"/store/video/album/list"}>专辑管理</Link>
        </Menu.Item>
      </Menu>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/store/video/album/add"}>
            <Button type="primary">新建专辑</Button>
          </Link>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storeVideoAlbumId}
        pagination={paginationProps}
        columns={[
          {
            title: "专辑ID",
            dataIndex: "storeVideoAlbumId",
            key: "storeVideoAlbumId",
            width: 100,
          },
          {
            title: "专辑名称",
            dataIndex: "storeVideoAlbumName",
            key: "storeVideoAlbumName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeVideoAlbum) => (
              <Space size="middle">
                <Link to={ "/store/video/album/update/" + storeVideoAlbum.storeVideoAlbumId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteStoreVideoAlbum(storeVideoAlbum.storeVideoAlbumId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
