import { Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useEditStoreAccountPwd,
} from "../../services/store-account-service";
import { useDocumentTitle } from "../../utils";

export const StoreAccountUpdatePage = () => {
  //标题
  useDocumentTitle("修改密码", true);
  const { pathname } = useLocation();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //新建
  const useMutateEditStoreAccountPwd = useEditStoreAccountPwd;
  const { mutateAsync, error } = useMutateEditStoreAccountPwd();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then((res) => {
      if (res.status == 1) {
        message.info(res.msg);
      } else {
        message.info("修改成功");
      }
      setSubmitLoading(false);
    });
  };
  return (
    <div>
      <Form form={form} {...layout} onFinish={onFinish}>
        <Form.Item
          label={"旧密码"}
          name={"oldPassword"}
          rules={[{ required: true, message: "旧密码必填" }]}
        >
          <Input.Password placeholder={"请输入旧密码"} />
        </Form.Item>

        <Form.Item
          label={"新密码"}
          name={"newPassword"}
          rules={[{ required: true, message: "新密码必填" }]}
        >
          <Input.Password placeholder={"请输入新密码"} />
        </Form.Item>

        <Form.Item
          label={"确认密码"}
          name={"againPassword"}
          rules={[{ required: true, message: "确认密码必填" }]}
        >
          <Input.Password placeholder={"请输入确认密码"} />
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
