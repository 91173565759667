import { useMutation, useQuery, useQueryClient } from "react-query";
import { Goods } from "../interfaces/goods";
import { StoreFloorGoods } from "../interfaces/store-floor-goods";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreFloorGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeFloorGoodsList', param], () => client('storeFloorGoods/listStoreFloorGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useStoreFloorGoodsListNo = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeFloorGoodsListNo', param], () => client('storeFloorGoods/listNoInStoreFloorGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddStoreFloorGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreFloorGoods>) => client(`storeFloorGoods/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFloorGoodsListNo')
        }
    )
}

export const useDeleteStoreFloorGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId, storeFloorId }: { goodsId: number, storeFloorId: number}) =>
            client(`storeFloorGoods/delete?goodsId=${goodsId}&storeFloorId=${storeFloorId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFloorGoodsList')
        }
    );
};