import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Radio
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddStoreVideoAlbum } from "../../services/store-video-album-service";
import { useDocumentTitle } from "../../utils";

export const StoreVideoAlbumAddPage = () => {
  //标题
  useDocumentTitle("新建专辑", true);
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //新建
  const useMutateAddStoreVideoAlbum = useAddStoreVideoAlbum;
  const { mutateAsync, error } = useMutateAddStoreVideoAlbum();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/store/video/album/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storeVideoAlbum"]}
      >
        <Menu.Item key="storeVideo">
          <Link to={"/store/video/list"}>视频管理</Link>
        </Menu.Item>
        <Menu.Item key="storeVideoAlbum">
          <Link to={"/store/video/album/list"}>专辑管理</Link>
        </Menu.Item>
      </Menu>
      <Row>
        <Col span={12}>
          <Link to={"/store/video/album/list"}>
            <Button type="primary">专辑列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          storeVideoAlbumSort: 0,
          storeVideoAlbumShow: true,
        }}
      >
        <Form.Item
          label={"专辑名称"}
          name={"storeVideoAlbumName"}
          rules={[{ required: true, message: "专辑名称必填" }]}
        >
          <Input placeholder={"请输入专辑名称"} />
        </Form.Item>

        <Form.Item
          label={"专辑排序"}
          name={"storeVideoAlbumSort"}
          rules={[{ required: true, message: "专辑排序必填" }]}
        >
          <Input placeholder={"请输入专辑排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"storeVideoAlbumShow"}
          rules={[{ required: true, message: "专辑显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>显示</Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
