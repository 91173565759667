import { useMutation, useQuery } from "react-query";
import { PageUrl } from "../interfaces/page-url";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const usePageUrlList = (param?: Partial<PageUrl>) => {
    const client = useHttp();
    const result = useQuery<any>(['pageUrlList', param], () => client('pageUrl', {data: cleanObject(param)}))
    const data = result.data?.data;
    return {...result, data };
}

export const usePageUrl = () => {
    const client = useHttp();
    return useMutation(
        (params: Partial<PageUrl>) =>
            client(`pageUrl/${params.pageUrlId}`, {
                method: "GET"
            }),
    );
};