import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Radio,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddStorePhotoAlbum } from "../../services/store-photo-album-service";
import { useDocumentTitle } from "../../utils";

export const StorePhotoAlbumAddPage = () => {
  //标题
  useDocumentTitle("新建相册", true);
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //新建
  const useMutateAddStorePhotoAlbum = useAddStorePhotoAlbum;
  const { mutateAsync, error } = useMutateAddStorePhotoAlbum();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/store/photo/album/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storePhotoAlbum"]}
      >
        <Menu.Item key="storePhoto">
          <Link to={"/store/photo/list"}>相片管理</Link>
        </Menu.Item>
        <Menu.Item key="storePhotoAlbum">
          <Link to={"/store/photo/album/list"}>相册管理</Link>
        </Menu.Item>
      </Menu>
      <Row>
        <Col span={12}>
          <Link to={"/store/photo/album/list"}>
            <Button type="primary">相册列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          storePhotoAlbumSort: 0,
          storePhotoAlbumShow: true,
        }}
      >
        <Form.Item
          label={"相册名称"}
          name={"storePhotoAlbumName"}
          rules={[{ required: true, message: "相册名称必填" }]}
        >
          <Input placeholder={"请输入相册名称"} />
        </Form.Item>

        <Form.Item
          label={"相册排序"}
          name={"storePhotoAlbumSort"}
          rules={[{ required: true, message: "相册排序必填" }]}
        >
          <Input placeholder={"请输入相册排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"storePhotoAlbumShow"}
          rules={[{ required: true, message: "专辑显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>显示</Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
