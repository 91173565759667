import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Card, Form, Select } from 'antd';
import { useSeckillList, useDeleteSeckill } from '../../services/seckill-service';
import { useDebounce, useDocumentTitle } from '../../utils';
import { useSeckillSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
const { Option } = Select;

export const SeckillListPage = () => {
    useDocumentTitle('秒杀列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //参数
    const [param, setParam] = useSeckillSearchParams();
    const { isLoading, error, totalElements, data: list } = useSeckillList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //删除事件
    const useMutateDeleteSeckill = useDeleteSeckill;
    const { mutateAsync } = useMutateDeleteSeckill();
    const confirmDeleteSeckill = (seckillId: number) => {
        Modal.confirm({
            title: "确定失效这条数据吗?",
            content: "点击确定失效",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ seckillId }).then(() => {
                        message.info('操作成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //完成
    const onFinish = (values: any) => {
        setParam({
            ...param,
            seckillTitle: values.seckillTitle,
            seckillState: values.seckillState,
            pageNum: null
        })
    }
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/application/seckill-manage/seckill/add"}>
                        <Button type="primary">新建秒杀</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="seckillTitle"
                                label="活动名称"
                            >
                                <Input placeholder="请输入活动名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="seckillState"
                                label="活动状态"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择活动状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={1}>未开始</Option>
                                    <Option value={2}>进行中</Option>
                                    <Option value={3}>已结束</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div>
                <Table
                    loading={isLoading}
                    pagination={paginationProps}
                    rowKey={columns => columns.seckillId}
                    columns={[
                        {
                            title: '活动商品',
                            width: 300,
                            fixed: 'left',
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            render: (value, seckill) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl  + seckill.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{ paddingLeft: 10 }}>
                                            {seckill.goodsName}
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '活动名称',
                            dataIndex: 'seckillTitle',
                            key: 'seckillTitle'
                        },
                        {
                            title: '活动时间',
                            dataIndex: 'seckillStartTime',
                            key: 'seckillStartTime',
                            width: 200,
                            render(value, seckill) {
                                return <span>
                                    {seckill.seckillStartTime ? dayjs(seckill.seckillStartTime).format('YYYY-MM-DD HH:mm:ss') : '无'}<br />
                                    ～<br />
                                    {seckill.seckillEndTime ? dayjs(seckill.seckillEndTime).format('YYYY-MM-DD HH:mm:ss') : '无'}
                                </span>
                            },
                        },
                        {
                            title: '订单实付金额(元)',
                            dataIndex: 'seckillOrdersAmount',
                            key: 'seckillOrdersAmount'
                        },
                        {
                            title: '付款订单数',
                            dataIndex: 'seckillOrdersNum',
                            key: 'seckillOrdersNum'
                        },
                        {
                            title: '排序',
                            dataIndex: 'seckillSort',
                            key: 'seckillSort',
                        },
                        {
                            title: "门店",
                            dataIndex: "storeName",
                            key: "storeName",
                            width: 250,
                        },
                        {
                            title: "显示区域",
                            dataIndex: "areaName",
                            key: "areaName"
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, seckill) => (
                                <Space size="middle">
                                    {seckill.seckillStatus === true ? <a onClick={() => confirmDeleteSeckill(seckill.seckillId)}>
                                        失效
                                    </a> : ''}<br />
                                    <Link to={'/application/seckill-manage/seckill/update/' + seckill.seckillId}>
                                        编辑
                                    </Link>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={list || []}
                    scroll={{ x: 1800 }} />
            </div>
            <ErrorBox error={error} />
        </div>
    )
}