import { useMutation, useQuery, useQueryClient } from "react-query";
import { CouponPackage } from "../interfaces/coupon-package";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCouponPackageList = (param?: Partial<CouponPackage>) => {
    const client = useHttp();
    const result = useQuery<any>(['couponPackageList', param], () => client('couponPackage', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCouponPackage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CouponPackage>) => client(`couponPackage`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponPackageList')
        }
    )
}

export const useAddCouponPackage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CouponPackage>) => client(`couponPackage`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponPackageList')
        }
    )
}

export const useDeleteCouponPackage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ couponPackageId }: { couponPackageId: number}) =>
            client(`couponPackage/${couponPackageId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponPackageList')
        }
    );
};

export const useCouponPackage = (couponPackageId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['couponPackage', {couponPackageId}],
        () => client(`couponPackage/${couponPackageId}`),
        {
            enabled: Boolean(couponPackageId)
        }
    )
}