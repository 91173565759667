import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useCouponPackageList, useDeleteCouponPackage } from "../../services/coupon-package-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCouponPackageSearchParams } from "./util"

export const CouponPackageListPage = () => {
    useDocumentTitle('优惠券礼包列表', true)
    const [param, setParam] = useCouponPackageSearchParams()
    const {isLoading, error, totalElements, data: list} = useCouponPackageList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteCouponPackage = useDeleteCouponPackage;
    const { mutateAsync } = useMutateDeleteCouponPackage();
    const confirmDeleteCouponPackage = (couponPackageId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ couponPackageId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/application/couponPackage/add"}>
                        <Button type="primary">新建礼包</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索礼包标题" 
                        style={{ width: 200 }}
                        value={param.couponPackageTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            couponPackageTitle: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            couponPackageTitle: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.couponPackageId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '礼包ID',
                    dataIndex: 'couponPackageId',
                    key: 'couponPackageId',
                    width: 100,
                },
                {
                    title: '礼包标题',
                    dataIndex: 'couponPackageTitle',
                    key: 'couponPackageTitle',
                },
                { 
                    title: '已领取', 
                    dataIndex: 'couponPackageReceive', 
                    key: 'couponPackageReceive' 
                },
                { 
                    title: '剩余', 
                    dataIndex: 'couponPackageSurplus', 
                    key: 'couponPackageSurplus' 
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, couponPackage) => (
                        <Space size="middle">
                            <Link to={"/application/couponPackage/update/" + couponPackage.couponPackageId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCouponPackage(couponPackage.couponPackageId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}