import { Button, Col, message, Modal, Row, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Link, useLocation } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useStoreAccountList,
  useDeleteStoreAccount,
  useResetPassword,
} from "../../services/store-account-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreAccountSearchParams } from "./util";

export const StoreAccountListPage = () => {
  useDocumentTitle("账号列表", true);
  const [param, setParam] = useStoreAccountSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useStoreAccountList(useDebounce(param, 200));
  const { pathname } = useLocation();
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreAccount = useDeleteStoreAccount;
  const { mutateAsync: mutateAsyncDeleteStoreAccount } =
    useMutateDeleteStoreAccount();
  const confirmDeleteStoreAccount = (storeAccountId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncDeleteStoreAccount({ storeAccountId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //重置密码
  const useMutateEditStoreAccount = useResetPassword;
  const { mutateAsync: mutateAsyncEditStoreAccount } =
    useMutateEditStoreAccount();
  const confirmEditStoreAccount = (storeAccountId: number) => {
    Modal.confirm({
      title: "确定重置密码吗?",
      content: "点击确定",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncEditStoreAccount({ storeAccountId }).then(() => {
            message.info("重置成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/setting/storeAccount/add/"}>
            <Button type="primary">新建账号</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索账号名称"
            style={{ width: 200 }}
            value={param.storeAccountName}
            onChange={(evt) =>
              setParam({
                ...param,
                storeAccountName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                storeAccountName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storeAccountId}
        pagination={paginationProps}
        columns={[
          {
            title: "账号ID",
            dataIndex: "storeAccountId",
            key: "storeAccountId",
            width: 100,
          },
          {
            title: "账号名称",
            dataIndex: "storeAccountName",
            key: "storeAccountName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeAccount) => (
              <Space size="middle">
                <a
                  onClick={() =>
                    confirmEditStoreAccount(storeAccount.storeAccountId)
                  }
                >
                  重置密码
                </a>
                <a
                  onClick={() =>
                    confirmDeleteStoreAccount(storeAccount.storeAccountId)
                  }
                >
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
