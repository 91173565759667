import qs from 'qs'
import { useCallback } from 'react'
import { useAuth } from '../context/auth-context'
import * as auth from './auth-provider'

const apiUrl = process.env.REACT_APP_API_URL

interface Config extends RequestInit {
    token?: string,
    data?: object
}

export const http = async (endpoint: string, {data, token, headers, ...customConfig}: Config = {}) => {
    const config = {
        method: 'GET',
        headers: {
            Authorization: token ? `Bearer ${token}` : "",
            'Content-Type': data ? 'application/json' : '',
        },
        ...customConfig
    }
    if(config.method.toUpperCase() === 'GET') {
        const datas = qs.stringify(data)
        if(datas != null && datas.length > 0){
            endpoint += `?${qs.stringify(data)}`;
        } else {
            endpoint += `${qs.stringify(data)}`;
        }
    } else {
        config.body = JSON.stringify(data || {})
    }
    return window.fetch(`${apiUrl}/${endpoint}`, config)
        .then(async response => {
            if(response.status === 401) {
                await auth.logout();
                window.location.reload();
                return Promise.reject({message: '请重新登录'})
            }
            const data = await response.json()
            if(response.ok) {
                return data
            } else {
                return Promise.reject(data)
            }
        })
}

export const useHttp = () => {
    const {storeAccount} = useAuth()
    // utility type的用法：用范型给它传入一个其他类型，然后utility type对这个类型进行某种操作
    return useCallback((...[endpoint, config]: Parameters<typeof http>) => 
    http(endpoint, {...config, token: storeAccount?.token}), [storeAccount?.token])
}