import { Button, Col, Dropdown, Menu, message, Modal, Row, Space, Table, Image } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useStoreFloorList, useDeleteStoreFloor } from "../../services/store-floor-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreFloorSearchParams } from "./util"
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'

export const StoreFloorListPage = () => {
    useDocumentTitle('楼层列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useStoreFloorSearchParams()
    const {isLoading, error, totalElements, data: list} = useStoreFloorList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteStoreFloor = useDeleteStoreFloor;
    const { mutateAsync } = useMutateDeleteStoreFloor();
    const confirmDeleteStoreFloor = (storeFloorId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ storeFloorId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //下拉菜单
    const menu:any = (record:any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/store/floor/goods/list/" + record.storeFloorId}>
                        已选商品列表
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={"/store/floor/goods/listNo/" + record.storeFloorId}>
                        未选商品列表
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/store/floor/add"}>
                        <Button type="primary">新建楼层</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索楼层标题" 
                        style={{ width: 200 }}
                        value={param.storeFloorTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storeFloorTitle: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storeFloorTitle: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                pagination = { paginationProps } 
                rowKey={ columns => columns.storeFloorId } 
                columns = {[
                {
                    title: '楼层ID',
                    dataIndex: 'storeFloorId',
                    key: 'storeFloorId',
                    width: 100,
                },
                {
                    title: '楼层标题',
                    dataIndex: 'storeFloorTitle',
                    key: 'storeFloorTitle',
                },
                {
                    title: '楼层图片',
                    dataIndex: 'storeFloorPic',
                    key: 'storeFloorPic',
                    render: (value, storeFloor) => (
                        <Image width={150} src={imgUrl + storeFloor.storeFloorPic}/>
                    ),
                },
                {
                    title: '楼层排序',
                    dataIndex: 'storeFloorSort',
                    key: 'storeFloorSort',
                },
                {
                    title: '是否显示',
                    dataIndex: 'storeFloorShow',
                    key: 'storeFloorShow',
                    render: (value, storeFloor) => (
                        <span>{storeFloor.storeFloorShow ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, storeFloor) => (
                        <Space size="middle">
                            <Dropdown overlay={menu.bind(this, storeFloor)}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    商品 <DownOutlined />
                                </a>
                            </Dropdown>
                            <Link to={"/store/floor/update/" + storeFloor.storeFloorId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteStoreFloor(storeFloor.storeFloorId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 200,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}