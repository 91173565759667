import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Spin,
  Radio
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStoreVideoAlbum, useEditStoreVideoAlbum } from "../../services/store-video-album-service";
import { useDocumentTitle } from "../../utils";

export const StoreVideoAlbumUpdatePage = () => {
  //标题
  useDocumentTitle("编辑专辑", true);
  //API地址
  const { pathname } = useLocation();
  const storeVideoAlbumId = pathname.match(/store\/video\/album\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingStoreVideoAlbum, isLoading } = useStoreVideoAlbum(
    Number(storeVideoAlbumId)
  );
  useEffect(() => {
    form.setFieldsValue(editingStoreVideoAlbum?.data);
  }, [editingStoreVideoAlbum, form]);
  //编辑
  const useMutateEditStoreVideoAlbum = useEditStoreVideoAlbum;
  const { mutateAsync, error } = useMutateEditStoreVideoAlbum();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingStoreVideoAlbum?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/store/video/album/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storeVideoAlbum"]}
      >
        <Menu.Item key="storeVideo">
          <Link to={ "/store/video/list" }>
            视频管理
          </Link>
        </Menu.Item>
        <Menu.Item key="storeVideoAlbum">
          <Link to={ "/store/video/album/list" }>
            专辑管理
          </Link>
        </Menu.Item>
      </Menu>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/store/video/album/list" }>
              <Button type="primary">专辑列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"专辑名称"}
            name={"storeVideoAlbumName"}
            rules={[{ required: true, message: "专辑名称必填" }]}
          >
            <Input placeholder={"请输入专辑名称"} />
          </Form.Item>

          <Form.Item
            label={"专辑排序"}
            name={"storeVideoAlbumSort"}
            rules={[{ required: true, message: "相册专辑必填" }]}
          >
            <Input placeholder={"请输入专辑排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"storeVideoAlbumShow"}
            rules={[{ required: true, message: "专辑显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
