import { Divider, Upload, Row, Col, Button, Input, Form, Radio, DatePicker, message, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEditCoupon, useCoupon } from '../../services/coupon-service';
import { useAreaListStore } from "../../services/area-service";
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { getToken } from '../../utils/auth-provider';
const { RangePicker } = DatePicker;
const { Option } = Select;

export const CouponUpdatePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const token = getToken();
    const { pathname } = useLocation();
    const couponId = pathname.match(/application\/coupon-manage\/coupon\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备区域数据
    const { data: areaList } = useAreaListStore();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => 
        <Option value={item.areaId} key={item.areaId}>{item.areaName}</Option>
    );
    const [form] = useForm();
    const [couponStartTimeStr, setCouponStartTimeStr] = useState();
    const [couponEndTimeStr, setCouponEndTimeStr] = useState();
    const [storage, setStorage] = useState(0); 
    const handleSelectTime = (value:any ,dateString:any) => {
        setCouponStartTimeStr(dateString[0]);
        setCouponEndTimeStr(dateString[1]);
    }
    //准备回显数据
    const { data: editingCoupon, isLoading } = useCoupon(
        Number(couponId)
    );
    const [imageUrl, setImageUrl] = useState("");
    const [limit, setLimit] = useState<any>(false);
    const [expiryType, setExpiryType] = useState<any>(1);
    useEffect(() => {
        form.setFieldsValue(editingCoupon?.data)
        const picUrl = imgUrl  + editingCoupon?.data?.couponPic;
        setImageUrl(picUrl)
        setStorage(editingCoupon?.data?.couponTotalStorage)
        const couponLimit = editingCoupon?.data?.couponLimit
        if(couponLimit != null && couponLimit) {
            setLimit(true)
        } else {
            setLimit(false)
        }
        const couponExpiryType = editingCoupon?.data?.couponExpiryType
        if(couponExpiryType == 1) {
            setExpiryType(1)
        } else {
            setExpiryType(2)
        }
        //时间
        setCouponStartTimeStr(editingCoupon?.data?.couponStartTimeStr)
        setCouponEndTimeStr(editingCoupon?.data?.couponEndTimeStr)
        form.setFieldsValue({
            "couponTimeRange": [moment(editingCoupon?.data?.couponStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingCoupon?.data?.couponEndTimeStr, "YYYY-MM-DD HH:mm:ss")]
        })
    }, [editingCoupon, form]);
    //图片上传
    const [uploadLoading, setUploadLoading] = useState(false);
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                couponPic: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //是否有门槛
    const handleLimit = (e:any) => {
        setLimit(e.target.value);
    };
    //过期类型
    const handleExpiryType = (e:any) => {
        setExpiryType(e.target.value);
    };
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    //编辑
    const useMutateCoupon = useEditCoupon;
    const { mutateAsync, error } = useMutateCoupon();
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        if(values.couponTotalStorage < storage) {
            setSubmitLoading(false);
            message.error('发放总量只能增加不能减少');
            return;
        }
        mutateAsync({ ...editingCoupon?.data, ...values, couponStartTimeStr, couponEndTimeStr }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/application/coupon-manage/coupon/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/application/coupon-manage/coupon/list"}>
                        <Button type="primary">优惠券列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
            >
                <Form.Item
                    label={"优惠券所属"}
                    name={"couponCategory"}
                    rules={[{ required: true, message: "优惠券所属必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group disabled={true}>
                        <Radio value={1}>普通优惠券</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={"优惠券标题"}
                    name={"couponTitle"}
                    rules={[{ required: true, message: "优惠券标题必填" }]}
                >
                    <Input placeholder={"请输入优惠券标题"} />
                </Form.Item>

                <Form.Item
                    label={"优惠券副标题"}
                    name={"couponSubTitle"}
                >
                    <Input placeholder={"请输入优惠券副标题"} />
                </Form.Item>

                <Form.Item
                    name={"uploadPic"}
                    label={"优惠券图片"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: "优惠券图片必传" }]}
                    style={{ textAlign:'left' }}
                    initialValue={[
                        {
                        "uid": "",
                        "lastModified": "",
                        "lastModifiedDate": "",
                        "name": "",
                        "size": "",
                        "type": "",
                        "percent": "",
                        "originFileObj": {
                            "uid": ""
                        },
                        "status": "done",
                        "response": {
                            "status": 0,
                            "data": {
                            "relativePath": "",
                            "realPath": ""
                            }
                        },
                        "xhr": {}
                        }
                    ]}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        headers={{ "Authorization": "Bearer " + token }}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"优惠券图片"}
                    name={"couponPic"}
                    rules={[{ required: true, message: "优惠券图片必传" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    extra="修改优惠券总量时只能增加不能减少，请谨慎设置"
                    label={"发放总量"}
                    name={"couponTotalStorage"}
                    rules={[{ required: true, message: "发放总量必填" }]}
                >
                    <Input placeholder={"请输入发放总量"} />
                </Form.Item>

                <Form.Item
                    label={"每人最多领取数"}
                    name={"couponMaxReceive"}
                    rules={[{ required: true, message: "每人最多领取数必填" }]}
                >
                    <Input readOnly={true} placeholder={"请输入每人最多领取数"} />
                </Form.Item>

                <Form.Item
                    label={"优惠券类型"}
                    name={"couponType"}
                    rules={[{ required: true, message: "优惠券类型必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group disabled={true}>
                        <Radio value={1}>现金券</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={"优惠金额"}
                    name={"couponPrice"}
                    rules={[{ required: true, message: "优惠金额必填" }]}
                >
                    <Input placeholder={"请输入优惠金额"} />
                </Form.Item>

                <Form.Item
                    label={"使用门槛"}
                    name={"couponLimit"}
                    rules={[{ required: true, message: "使用门槛必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group disabled={true} onChange={handleLimit}>
                        <Radio defaultChecked value={false}>不限制</Radio>
                        <Radio value={true}>限制（满X元）</Radio>
                    </Radio.Group>
                </Form.Item>

                {limit == true? <Form.Item
                    label={"满X元"}
                    name={"couponLimitPrice"}
                    rules={[{ required: true, message: "满X元必填" }]}
                >
                    <Input placeholder={"请输入满X元"} />
                </Form.Item> : ''}
                    
                <Form.Item
                    label={"有效期类型"}
                    name={"couponExpiryType"}
                    rules={[{ required: true, message: "有效期类型必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group disabled={true} onChange={handleExpiryType}>
                        <Radio value={1}>固定日期</Radio>
                        <Radio value={2}>领券后X天</Radio>
                    </Radio.Group>
                </Form.Item>

                {expiryType == 1 ? <Form.Item 
                    name="couponTimeRange" 
                    label="有效时间" 
                    {...rangeConfig}
                    rules={[{ required: true, message: "有效时间必选" }]}
                    initialValue={[moment(editingCoupon?.data?.couponStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingCoupon?.data?.couponEndTimeStr, "YYYY-MM-DD HH:mm:ss")]}
                >
                    <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item> : ''}
                    
                {expiryType == 2 ? <Form.Item
                    label={"领券X天有效"}
                    name={"couponExpiryTime"}
                    rules={[{ required: true, message: "领券X天有效必填" }]}
                >
                    <Input placeholder={"请输入领券X天有效"} />
                </Form.Item>: ''}

                <Form.Item
                    label={"可使用商品"}
                    name={"couponLimitGoods"}
                    rules={[{ required: true, message: "可使用商品必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio value={false}>全部商品</Radio>
                        <Radio value={true}>指定商品</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={"优惠券排序"}
                    name={"couponSort"}
                    rules={[{ required: true, message: "优惠券排序必填" }]}
                >
                    <Input placeholder={"请输入优惠券排序"} />
                </Form.Item>
                    
                <Form.Item
                    label={"是否显示"}
                    name={"couponShow"}
                    rules={[{ required: true, message: "优惠券显示必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                {/* <Form.Item
                    label={"显示区域"}
                    name={"areaId"}
                    rules={[{ required: true, message: "显示区域必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择显示区域"
                        optionFilterProp="children"
                        style={{textAlign:'left'}}
                    >
                        <option value={0}>全部</option>
                        {areaListOptions}
                    </Select>
                </Form.Item> */}

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}