import { Card, Col, message, Row, Image, Space, Table, Typography } from "antd"
import Search from "antd/lib/input/Search"
import { useDocumentTitle } from "../../utils";
import { useAddOrdersParcelOnGoodsDetail } from "../../services/orders-parcel-service";
import { useState } from "react";
const { Text } = Typography;

export const OrdersVerificationPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL
    useDocumentTitle('订单列表', true)
    //参数
    const useMutateAddOrdersParcel = useAddOrdersParcelOnGoodsDetail;
    const { mutateAsync: mutateAsyncAddOrdersParcel } =
    useMutateAddOrdersParcel();
    const [orders, setOrders] = useState<any>();
    function handleChange(e: any) {
        setOrders(null)
        const ordersPickSn = e.target.value;
        if(ordersPickSn.length > 12) {
            mutateAsyncAddOrdersParcel({
                ordersPickSn
            }).then((res) => {
                if (res.status === 1) {
                    message.error(res.msg);
                } else {
                    message.success("核销成功");
                    setOrders(res.data)
                }              
            });
        }
    }
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Search
                    placeholder="请输入核销码"
                    style={{ width: 300 }}
                    onChange={handleChange}
                    onSearch={handleChange}
                    onPressEnter={handleChange}
                />
            </Card>
            {orders? <Row style={{ fontSize: 14, marginBottom: 24 }}>
                <Text style={{ marginRight: 30 }}>
                    联系人：{orders?.ordersReceiverName}
                </Text>
                <Text>
                    联系电话：{orders?.ordersReceiverTel}
                </Text>
            </Row> : ""}
            <Row style={{ marginBottom: 24 }}>
                <Table
                pagination={false}
                style={{ width: "100%" }}
                columns={[
                    {
                    title: "商品",
                    dataIndex: "goodsName",
                    key: "goodsName",
                    width: "30%",
                    render: (value, item) => (
                        <Space size="middle">
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl  + item.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{ paddingLeft: 10 }}>
                                    <Row>
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                        {item.goodsName}
                                        </Col>
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                        规格：{item.goodsSkuInfo}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Space>
                    ),
                    },
                    {
                        title: "单价(元)",
                        dataIndex: "goodsPrice",
                        key: "goodsPrice",
                        width: "11.6%",
                    },
                    {
                        title: "份数",
                        dataIndex: "goodsNum",
                        key: "goodsNum",
                        width: "11.6%",
                    },
                    {
                        title: "总次数",
                        dataIndex: "ordersGoodsNum",
                        key: "ordersGoodsNum",
                        width: "11.6%",
                    },
                    {
                        title: "剩余次数",
                        dataIndex: "ordersGoodsRemainNum",
                        key: "ordersGoodsRemainNum",
                        width: "11.6%",
                    },
                    {
                        title: "小计(元)",
                        dataIndex: "goodsTotalPrice",
                        key: "goodsTotalPrice",
                        width: "11.6%",
                    },
                    {
                        title: "售后状态",
                        dataIndex: "ordersGoodsRefundState",
                        key: "ordersGoodsRefundState",
                        width: "11.6%",
                        render(value, ordersGoods) {
                            return (
                            <span>
                                {ordersGoods.ordersGoodsRefundState == 0 ? "无售后" : ""}
                                {ordersGoods.ordersGoodsRefundState == 10
                                ? "退款申请待商家处理"
                                : ""}
                                {ordersGoods.ordersGoodsRefundState == 20
                                ? "商家不同意退款申请，待买家处理"
                                : ""}
                                {ordersGoods.ordersGoodsRefundState == 30
                                ? "商家已同意退款申请，待买家发货"
                                : ""}
                                {ordersGoods.ordersGoodsRefundState == 40
                                ? "买家已退货，待商家确认收货"
                                : ""}
                                {ordersGoods.ordersGoodsRefundState == 50
                                ? "商家拒绝收货，待买家处理"
                                : ""}
                                {ordersGoods.ordersGoodsRefundState == 60 ? "退款关闭" : ""}
                                {ordersGoods.ordersGoodsRefundState == 70 ? "退款成功" : ""}
                            </span>
                            );
                        },
                    }
                ]}
                dataSource={orders?.ordersGoodsList || []}
                />
            </Row>
        </div>
    )
}