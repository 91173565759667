import { useMutation, useQuery, useQueryClient } from "react-query";
import { Store } from "../interfaces/store";
import { useHttp } from "../utils/http";

export const useEditStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Store>) => client(`store`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('store')
        }
    )
}

export const useStore = () => {
    const client = useHttp()
    return useQuery<any>(
        ['store', {}],
        () => client(`store`)
    )
}

export const useStoreListAll = () => {
    const client = useHttp();
    return useQuery<any>(['storeListAll', {}], () => client('store/listAll'))
}


