import React, { ReactNode, useState } from "react";
import * as auth from "../utils/auth-provider";
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";
import { http } from "../utils/http";
import { useMount } from "../utils";
import { useAsync } from "../utils/use-async";
import { StoreAccount } from "../interfaces/store-account";
import { message } from "antd";

const localStorageKey = "__auth_provider_sm_city_mall_store_token__";

interface AuthForm {
  storeAccountName: string;
  storeAccountPassword: string;
}

//页面加载的时候执行
const bootstrapShop = async () => {
  let storeAccount = null;
  const token = auth.getToken();
  if (token) {
    const data = await http("storeAccount", { token });
    const status = data.status;
    console.log(status);
    if (status == 0) {
      const result = data.data.token;
      if (result != null) {
        storeAccount = data.data;
      }
    } else {
      window.localStorage.removeItem(localStorageKey);
    }
  }
  return storeAccount;
};

const AuthContext = React.createContext<
  | {
      storeAccount: StoreAccount | null;
      login: (form: AuthForm) => Promise<void>;
      register: (form: AuthForm) => Promise<void>;
      logout: () => Promise<void>;
    }
  | undefined
>(undefined);
AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    data: storeAccount,
    error,
    isLoading,
    isIdle,
    isError,
    run,
    setData: setStoreAccount,
  } = useAsync<StoreAccount | null>();
  const login = (form: AuthForm) =>
    auth.login(form).then(async (response) => {
      const status = response.status;
      if (status == 0) {
        const token = response.data.token;
        window.localStorage.setItem(localStorageKey, token || "");
      } else {
        message.info(response.msg);
      }
      setStoreAccount(response.data);
    });
  const register = (form: AuthForm) =>
    auth.register(form).then(setStoreAccount);
  const logout = () => auth.logout().then(() => setStoreAccount(null));
  //页面加载的时候
  useMount(() => {
    run(bootstrapShop());
  });
  if (isIdle || isLoading) {
    return <FullPageLoading />;
  }
  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }
  return (
    <AuthContext.Provider
      children={children}
      value={{ storeAccount, login, register, logout }}
    />
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth必须在AuthProvider中使用");
  }
  return context;
};
