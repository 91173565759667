import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreVideoAlbum } from "../interfaces/store-video-album";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreVideoAlbumList = (param?: Partial<StoreVideoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeVideoAlbumList', param], () => client('storeVideoAlbum', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreVideoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreVideoAlbum>) => client(`storeVideoAlbum`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoAlbumList')
        }
    )
}

export const useAddStoreVideoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreVideoAlbum>) => client(`storeVideoAlbum`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoAlbumList')
        }
    )
}

export const useDeleteStoreVideoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeVideoAlbumId }: { storeVideoAlbumId: number}) =>
            client(`storeVideoAlbum/${storeVideoAlbumId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoAlbumList')
        }
    );
};

export const useStoreVideoAlbum = (storeVideoAlbumId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeVideoAlbum', {storeVideoAlbumId}],
        () => client(`storeVideoAlbum/${storeVideoAlbumId}`),
        {
            enabled: Boolean(storeVideoAlbumId)
        }
    )
}

export const useStoreVideoAlbumListAll = () => {
    const client = useHttp()
    return useQuery<any>(
        ['storeVideoAlbumListAll', {}],
        () => client(`storeVideoAlbum/listAll`),
    )
}