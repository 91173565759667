import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useStoreVideoList, useDeleteStoreVideo} from "../../services/store-video-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreVideoSearchParams } from "./util";

export const StoreVideoListPage = () => {
  useDocumentTitle("视频列表", true);
  const [param, setParam] = useStoreVideoSearchParams();
  const { isLoading, error, totalElements, data: list } = useStoreVideoList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreVideo = useDeleteStoreVideo;
  const { mutateAsync } = useMutateDeleteStoreVideo();
  const confirmDeleteStoreVideo = (storeVideoId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storeVideoId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["searchHot"]}
      >
        <Menu.Item key="searchHot">
          <Link to={"/store/video/list"}>视频管理</Link>
        </Menu.Item>
        <Menu.Item key="searchDefault">
          <Link to={"/store/video/album/list"}>专辑管理</Link>
        </Menu.Item>
      </Menu>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/store/video/add"}>
            <Button type="primary">新建视频</Button>
          </Link>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storeVideoId}
        pagination={paginationProps}
        columns={[
          {
            title: "视频ID",
            dataIndex: "storeVideoId",
            key: "storeVideoId",
            width: 100,
          },
          {
            title: "视频名称",
            dataIndex: "storeVideoName",
            key: "storeVideoName",
          },
          {
            title: "所属专辑",
            dataIndex: "storeVideoAlbumName",
            key: "storeVideoAlbumName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeVideo) => (
              <Space size="middle">
                <Link to={"/store/video/update/" + storeVideo.storeVideoId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteStoreVideo(storeVideo.storeVideoId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
