import { Layout } from "antd";
import { SiderLayout } from "../../layouts/sider";
import { HeaderLayout } from "../../layouts/header";
import { FooterLayout } from "../../layouts/footer";
import { DashboardPage } from "../dashboard/dashboard";
import { StoreUpdatePage } from "../store/update";
import { StoreAdvListPage } from "../store-adv/list";
import { StoreAdvAddPage } from "../store-adv/add";
import { StoreAdvUpdatePage } from "../store-adv/update";
import { StoreFloorListPage } from '../store-floor/list';
import { StoreFloorAddPage } from '../store-floor/add';
import { StoreFloorUpdatePage } from '../store-floor/update';
import { StoreFloorGoodsListPage } from '../store-floor-goods/list';
import { StoreFloorGoodsListNoPage } from '../store-floor-goods/list-no';
import { StorePhotoListPage } from "../store-photo/list";
import { StorePhotoAddPage } from "../store-photo/add";
import { StorePhotoUpdatePage } from "../store-photo/update";
import { StorePhotoAlbumListPage } from "../store-photo-album/list";
import { StorePhotoAlbumAddPage } from "../store-photo-album/add";
import { StorePhotoAlbumUpdatePage } from "../store-photo-album/update";
import { StoreVideoListPage } from "../store-video/list";
import { StoreVideoAddPage } from "../store-video/add";
import { StoreVideoUpdatePage } from "../store-video/update";
import { StoreVideoAlbumListPage } from "../store-video-album/list";
import { StoreVideoAlbumAddPage } from "../store-video-album/add";
import { StoreVideoAlbumUpdatePage } from "../store-video-album/update";

import { GoodsListPage } from "../goods/list";
import { GoodsAddPage } from "../goods/add";
import { GoodsUpdatePage } from "../goods/update";
import { GoodsClassListPage } from "../goods-class/list";
import { GoodsClassAddPage } from "../goods-class/add";
import { GoodsClassUpdatePage } from "../goods-class/update";
import { GoodsTagListPage } from "../goods-tag/list";
import { GoodsTagAddPage } from "../goods-tag/add";
import { GoodsTagUpdatePage } from "../goods-tag/update";
import { GoodsServeTagListPage } from "../goods-serve-tag/list";
import { GoodsServeTagAddPage } from "../goods-serve-tag/add";
import { GoodsServeTagUpdatePage } from "../goods-serve-tag/update";
import { GoodsEvaluateListPage } from "../goods-evaluate/list";


import { GrouponAddPage } from "../groupon/add";
import { GrouponListPage } from "../groupon/list";
import { GrouponUpdatePage } from "../groupon/update";
import { GrouponAdvAddPage } from "../groupon-adv/add";
import { GrouponAdvListPage } from "../groupon-adv/list";
import { GrouponAdvUpdatePage } from "../groupon-adv/update";

import { CouponListPage } from "../coupon/list";
import { CouponAddPage } from "../coupon/add";
import { CouponUpdatePage } from "../coupon/update";
import { CouponGoodsListPage } from "../coupon-goods/list";
import { CouponGoodsListNoPage } from "../coupon-goods/list-no";
import { CouponPackageListPage } from "../coupon-package/list";
import { CouponPackageAddPage } from "../coupon-package/add";
import { CouponPackageUpdatePage } from "../coupon-package/update";
// import { MemberCouponListPage } from "../member-coupon/list";
// import { MemberCouponListUsePage } from "../member-coupon/list-use";
// import { MemberCouponListNoUsePage } from "../member-coupon/list-no-use";

import { SeckillAddPage } from "../seckill/add";
import { SeckillListPage } from "../seckill/list";
import { SeckillUpdatePage } from "../seckill/update";

import { PointsAdvListPage } from "../points-adv/list";
import { PointsAdvAddPage } from "../points-adv/add";
import { PointsAdvUpdatePage } from "../points-adv/update";
import { PointsGoodsClassListPage } from "../points-goods-class/list";
import { PointsGoodsClassAddPage } from "../points-goods-class/add";
import { PointsGoodsClassUpdatePage } from "../points-goods-class/update";
import { PointsGoodsListPage } from "../points-goods/list";
import { PointsGoodsAddPage } from "../points-goods/add";
import { PointsGoodsUpdatePage } from "../points-goods/update";


import { OrdersListPage } from "../orders/list";
import { OrdersDetailPage } from "../orders/detail";
import { OrdersVerificationPage } from "../orders/verification";
import { OrdersRefundListPage } from "../orders-refund/list";
import { OrdersRefundDetailPage } from "../orders-refund/detail";
import { StoreBillListPage } from "../store-bill/list";
import { StoreBillDetailListPage } from "../store-bill-detail/list";
import { StoreMoneyLogListPage } from "../store-money-log/list";
import { StoreWithdrawCashListPage } from "../store-withdraw-cash/list";
import { StoreWithdrawCashAddPage } from "../store-withdraw-cash/add";
import { StoreSettleAccountListPage } from "../store-settle-account/list";
import { StoreSettleAccountAddPage } from "../store-settle-account/add";
import { StoreSettleAccountUpdatePage } from "../store-settle-account/update";
import { StoreAccountListPage } from "../store-account/list";
import { StoreAccountAddPage } from "../store-account/add";
import { StoreAccountUpdatePage } from "../store-account/update";
import { StoreGoodsClassListPage } from "../store-goods-class/list";
import { StoreGoodsClassAddPage } from "../store-goods-class/add";
import { StoreGoodsClassUpdatePage } from "../store-goods-class/update";
import { GoodsSpecListPage } from "../goods-spec/list";
import { GoodsSpecAddPage } from "../goods-spec/add";
import { GoodsSpecUpdatePage } from "../goods-spec/update";
import { GoodsSpecValueListPage } from "../goods-spec-value/list";
import { GoodsSpecValueAddPage } from "../goods-spec-value/add";
import { GoodsSpecValueUpdatePage } from "../goods-spec-value/update";
import { GoodsRecommedListPage } from "../goods/listRecommed";
import { Route, Routes, Navigate } from "react-router";
import { HashRouter as Router } from "react-router-dom";
const { Content } = Layout;

export const Authenticated = () => {
  return (
    <Layout>
      <Router>
        <SiderLayout />
        <Layout className="site-layout">
          <HeaderLayout />
          <Content
            style={{
              margin: "24px 16px 0",
              padding: 24,
              minHeight: "calc(100vh - 150px)",
              overflow: "initial",
              background: "#fff",
            }}
          >
            <Routes>
              <Route path={"/dashboard"} element={<DashboardPage />} />
              <Route path={"/store/info"} element={<StoreUpdatePage />} />
              <Route path={"/store/adv/list"} element={<StoreAdvListPage />} />
              <Route path={"/store/adv/add"} element={<StoreAdvAddPage />} />
              <Route path={"/store/adv/update/:advId"}  element={<StoreAdvUpdatePage />} />
              <Route path={"/store/floor/list"} element={<StoreFloorListPage />} />
              <Route path={"/store/floor/add"} element={<StoreFloorAddPage />} />
              <Route path={"/store/floor/update/:storeFloorId"} element={<StoreFloorUpdatePage />} />
              <Route path={"/store/floor/goods/list/:storeFloorId"} element={<StoreFloorGoodsListPage />} />
              <Route path={"/store/floor/goods/listNo/:storeFloorId"} element={<StoreFloorGoodsListNoPage />} />
              <Route path={"/store/photo/list"} element={<StorePhotoListPage />} />
              <Route path={"/store/photo/add"} element={<StorePhotoAddPage />} />
              <Route path={"/store/photo/update/:storePhotoId"} element={<StorePhotoUpdatePage />} />
              <Route path={"/store/photo/album/list"} element={<StorePhotoAlbumListPage />} />
              <Route path={"/store/photo/album/add"} element={<StorePhotoAlbumAddPage />} />
              <Route path={"/store/photo/album/update/:storePhotoAlbumId"} element={<StorePhotoAlbumUpdatePage />} />
              <Route path={"/store/video/list"} element={<StoreVideoListPage />} />
              <Route path={"/store/video/add"} element={<StoreVideoAddPage />} />
              <Route path={"/store/video/update/:storeVideoId"} element={<StoreVideoUpdatePage />} />
              <Route path={"/store/video/album/list"}  element={<StoreVideoAlbumListPage />} />
              <Route path={"/store/video/album/add"} element={<StoreVideoAlbumAddPage />}  />
              <Route path={"/store/video/album/update/:storeVideoAlbumId"} element={<StoreVideoAlbumUpdatePage />} />
              <Route
                path={"/goods/recommed/list"}
                element={<GoodsRecommedListPage />}
              />

              <Route
                path={"/goods/class/list"}
                element={<StoreGoodsClassListPage />}
              />
              <Route
                path={"/goods/class/add"}
                element={<StoreGoodsClassAddPage />}
              />
              <Route
                path={"/goods/class/update/:storeGoodsClassId"}
                element={<StoreGoodsClassUpdatePage />}
              />

              <Route path={"/goods/manage/goods-manage/list"} element={<GoodsListPage />} />
              <Route path={"/goods/manage/goods-manage/add"} element={<GoodsAddPage />} />
              <Route
                path={"/goods/manage/goods-manage/update/:goodsId"}
                element={<GoodsUpdatePage />}
              />
              <Route
                path={"/goods/manage/spec/list"}
                element={<GoodsSpecListPage />}
              />
              <Route path={"/goods/manage/spec/add"} element={<GoodsSpecAddPage />} />
              <Route
                path={"/goods/manage/spec/update/:goodsSpecId"}
                element={<GoodsSpecUpdatePage />}
              />
              <Route
                path={"/goods/manage/spec/value/list/:goodsSpecId"}
                element={<GoodsSpecValueListPage />}
              />
              <Route
                path={"/goods/manage/spec/value/add/:goodsSpecId"}
                element={<GoodsSpecValueAddPage />}
              />
              <Route
                path={"/goods/manage/spec/value/update/:goodsSpecValueId"}
                element={<GoodsSpecValueUpdatePage />}
              />

              {/*  */}

              <Route
                path={"/goods/manage/goods-class/list"}
                element={<GoodsClassListPage />}
              />
              <Route
                path={"/goods/manage/goods-class/add"}
                element={<GoodsClassAddPage />}
              />
              <Route
                path={"/goods/manage/goods-class/update/:goodsClassId"}
                element={<GoodsClassUpdatePage />}
              />
              <Route path={"/goods/manage/goods-tag/list"} element={<GoodsTagListPage />} />
              <Route path={"/goods/manage/goods-tag/add"} element={<GoodsTagAddPage />} />
              <Route
                path={"/goods/manage/goods-tag/update/:goodsTagId"}
                element={<GoodsTagUpdatePage />}
              />
              <Route path={"/goods/manage/goods-server-tag/list"} element={<GoodsServeTagListPage />} />
              <Route path={"/goods/manage/goods-server-tag/add"} element={<GoodsServeTagAddPage />} />
              <Route
                path={"/goods/manage/goods-server-tag/update/:goodsServeTagId"}
                element={<GoodsServeTagUpdatePage />}
              />
              <Route
                path={"/goods/manage/goods-spec/list"}
                element={<GoodsSpecListPage />}
              />
              <Route path={"/goods/manage/goods-spec/add"} element={<GoodsSpecAddPage />} />
              <Route
                path={"/goods/manage/goods-spec/update/:goodsSpecId"}
                element={<GoodsSpecUpdatePage />}
              />
              <Route
                path={"/goods/manage/goods-spec/value/list/:goodsSpecId"}
                element={<GoodsSpecValueListPage />}
              />
              <Route
                path={"/goods/manage/goods-spec/value/add/:goodsSpecId"}
                element={<GoodsSpecValueAddPage />}
              />
              <Route
                path={"/goods/manage/goods-spec/value/update/:goodsSpecValueId"}
                element={<GoodsSpecValueUpdatePage />}
              />
              <Route
                path={"/goods/recommed/list"}
                element={<GoodsRecommedListPage />}
              />
              <Route
                path={"/goods/manage/evaluate/list"}
                element={<GoodsEvaluateListPage />}
              />



              <Route
                path={"/application/coupon-manage/coupon/list"}
                element={<CouponListPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/add"}
                element={<CouponAddPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/update/:couponId"}
                element={<CouponUpdatePage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/goods/list/:couponId"}
                element={<CouponGoodsListPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/goods/listNo/:couponId"}
                element={<CouponGoodsListNoPage />}
              />
              
              <Route
                path={"/application/coupon-manage/coupon-package/list"}
                element={<CouponPackageListPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon-package/add"}
                element={<CouponPackageAddPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon-package/update/:couponPackageId"}
                element={<CouponPackageUpdatePage />}
              />
              {/* <Route
                path={"/application/coupon-manage/coupon/member/list/:couponId"}
                element={<MemberCouponListPage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/member/listUse/:couponId"}
                element={<MemberCouponListUsePage />}
              />
              <Route
                path={"/application/coupon-manage/coupon/member/listNoUse/:couponId"}
                element={<MemberCouponListNoUsePage />}
              /> */}
              <Route
                path={"/application/seckill-manage/seckill/list"}
                element={<SeckillListPage />}
              />
              <Route
                path={"/application/seckill-manage/seckill/add"}
                element={<SeckillAddPage />}
              />
              <Route
                path={"/application/seckill-manage/seckill/update/:seckillId"}
                element={<SeckillUpdatePage />}
              />

              <Route
                path={"/application/groupon-manage/groupon/list"}
                element={<GrouponListPage />}
              />
              <Route
                path={"/application/groupon-manage/groupon/add"}
                element={<GrouponAddPage />}
              />
              <Route
                path={"/application/groupon-manage/groupon/update/:grouponId"}
                element={<GrouponUpdatePage />}
              />
              <Route
                path={"/application/groupon-manage/groupon/adv/list"}
                element={<GrouponAdvListPage />}
              />
              <Route
                path={"/application/groupon-manage/groupon/adv/add"}
                element={<GrouponAdvAddPage />}
              />
              <Route
                path={"/application/groupon-manage/groupon/adv/update/:grouponAdvId"}
                element={<GrouponAdvUpdatePage />}
              />
              
              <Route
                path={"/application/points-manage/adv/list"}
                element={<PointsAdvListPage />}
              />
              <Route
                path={"/application/points-manage/adv/add"}
                element={<PointsAdvAddPage />}
              />
              <Route
                path={"/application/points-manage/adv/update/:pointsAdvId"}
                element={<PointsAdvUpdatePage />}
              />
              <Route
                path={"/application/points-manage/goodsClass/list"}
                element={<PointsGoodsClassListPage />}
              />
              <Route
                path={"/application/points-manage/goodsClass/add"}
                element={<PointsGoodsClassAddPage />}
              />
              <Route
                path={
                  "/application/points-manage/goodsClass/update/:pointsGoodsClassId"
                }
                element={<PointsGoodsClassUpdatePage />}
              />
              <Route
                path={"/application/points-manage/goods/list"}
                element={<PointsGoodsListPage />}
              />
              <Route
                path={"/application/points-manage/goods/add"}
                element={<PointsGoodsAddPage />}
              />
              <Route
                path={"/application/points-manage/goods/update/:pointsGoodsId"}
                element={<PointsGoodsUpdatePage />}
              />

              {/*  */}
              <Route
                path={"/orders/manage/list"}
                element={<OrdersListPage />}
              />
              <Route
                path={"/orders/manage/detail/:ordersId"}
                element={<OrdersDetailPage />}
              />
              <Route
                path={"/orders/verification"}
                element={<OrdersVerificationPage />}
              />
              <Route
                path={"/orders/refund/list"}
                element={<OrdersRefundListPage />}
              />
              <Route
                path={"/orders/refund/detail/:ordersRefundId"}
                element={<OrdersRefundDetailPage />}
              />
              <Route path={"/asset/storeBill/list"} element={<StoreBillListPage />} />
              <Route path={"/asset/storeBillDetail/list"} element={<StoreBillDetailListPage />} />
              <Route path={"/asset/storeMoneyLog/list"} element={<StoreMoneyLogListPage />} />
              <Route
                path={"/asset/storeWithdrawCash/list"}
                element={<StoreWithdrawCashListPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/add"}
                element={<StoreWithdrawCashAddPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/list"}
                element={<StoreSettleAccountListPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/add"}
                element={<StoreSettleAccountAddPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/update/:storeSettleAccountId"}
                element={<StoreSettleAccountUpdatePage />}
              />
              <Route path={"/setting/storeAccount/list"} element={<StoreAccountListPage />} />
              <Route path={"/setting/storeAccount/add"} element={<StoreAccountAddPage />} />
              <Route
                path={"/setting/change/pwd"}
                element={<StoreAccountUpdatePage />}
              />
              <Route index element={<Navigate to={"/dashboard"} />}></Route>
            </Routes>
          </Content>
          <FooterLayout />
        </Layout>
      </Router>
    </Layout>
  );
};
