import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGoodsClass, useEditGoodsClass, useGoodsClassListTree } from "../../services/goods-class-service";
import { useDocumentTitle } from "../../utils"
import { getToken } from "../../utils/auth-provider";
import { useStoreAttributeListAll } from "../../services/store-attribute-service";
import { useStoreTypeListAll } from "../../services/store-type-service";
const { Option } = Select;

export const GoodsClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑分类', true)
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const token = getToken();
    const { pathname } = useLocation();
    const goodsClassId = pathname.match(/goods\/manage\/goods-class\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备树状结构数据
    const { data: goodsClassList } = useGoodsClassListTree();
    const goodsClassListOptions = goodsClassList?.data?.map((item: any, value: any) => 
        <Option value={item.goodsClassId} key={item.goodsClassId}>{item.goodsClassName}</Option>
    );
    //准备回显数据
    const { data: editingGoodsClass, isLoading } = useGoodsClass(
        Number(goodsClassId)
    );  
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrls, setImageUrls] = useState("");
    useEffect(() => {
        form.setFieldsValue(editingGoodsClass?.data)
        if(editingGoodsClass?.data?.goodsClassPic) {
            const picUrl = imgUrl  + editingGoodsClass?.data?.goodsClassPic;
            setImageUrl(picUrl)
        }
        if(editingGoodsClass?.data?.goodsClassBanner) {
            const picUrls = imgUrl  + editingGoodsClass?.data?.goodsClassBanner;
            setImageUrls(picUrls)
        }
    }, [editingGoodsClass, form]);
    //图片上传
    const [uploadLoading, setUploadLoading] = useState(false);
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                goodsClassName: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //图片二
    const [uploadLoadings, setUploadLoadings] = useState(false);
    const uploadButtons = (
        <div>
          {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUploads = (file:any) => {
        setImageUrls("");
        setUploadLoadings(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoadings(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoadings(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChanges = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoadings(false);
            setImageUrls(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                goodsClassBanner: relativePath
            })
        }
    };
    const normFiles = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

      //商家性质
  const { data: storeAttributeList } = useStoreAttributeListAll();
  const storeAttributeOptions = storeAttributeList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.storeAttributeId} key={item.storeAttributeId}>
        {item.storeAttributeName}
      </Option>
    )
  );
  //商家类型
  const { data: storeTypeList } = useStoreTypeListAll();
  const storeTypeOptions = storeTypeList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.storeTypeId} key={item.storeTypeId}>
        {item.storeTypeName}
      </Option>
    )
  );
  
  const [storeLogo, setStoreLogo] = useState("");
  const [storeBanner, setStoreBanner] = useState("");
  const [storeProvinceId, setStoreProvinceId] = useState();
  const [storeCityId, setStoreCityId] = useState();
  const [storeDistrictId, setStoreDistrictId] = useState();
  const [storeContent, setStoreContent] = useState("");
    //新增
    const useMutateEditGoodsClass = useEditGoodsClass;
    const {mutateAsync, error } =  useMutateEditGoodsClass();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingGoodsClass?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/goods/manage/goods-class/list"; 
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/goods/manage/goods-class/list"}>
                            <Button type="primary">分类列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"上级分类"}
                        name={"goodsClassParentId"}
                        rules={[{ required: true, message: "上级分类必选" }]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择上级分类"
                            optionFilterProp="children"
                            style={{textAlign:'left'}}
                        >
                            <option value={0}>无</option>
                            {goodsClassListOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={"分类名称"}
                        name={"goodsClassName"}
                        rules={[{ required: true, message: "分类名称必填" }]}
                    >
                        <Input placeholder={"请输入分类名称"} />
                    </Form.Item>

                    <Form.Item
                        name={"uploadPic"}
                        label={"分类图标"}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        style={{ textAlign:'left' }}
                        initialValue={[
                            {
                            "uid": "",
                            "lastModified": "",
                            "lastModifiedDate": "",
                            "name": "",
                            "size": "",
                            "type": "",
                            "percent": "",
                            "originFileObj": {
                                "uid": ""
                            },
                            "status": "done",
                            "response": {
                                "status": 0,
                                "data": {
                                "relativePath": "",
                                "realPath": ""
                                }
                            },
                            "xhr": {}
                            }
                        ]}
                    >
                        <Upload
                            name="imageFile"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={apiUrl + "/upload/img"}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            headers={{ "Authorization": "Bearer " + token }}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>

                    <Form.Item hidden
                        label={"分类图标"}
                        name={"goodsClassPic"}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={"uploadPics"}
                        label={"分类Banner"}
                        valuePropName="fileList"
                        getValueFromEvent={normFiles}
                        style={{ textAlign:'left' }}
                        initialValue={[
                            {
                            "uid": "",
                            "lastModified": "",
                            "lastModifiedDate": "",
                            "name": "",
                            "size": "",
                            "type": "",
                            "percent": "",
                            "originFileObj": {
                                "uid": ""
                            },
                            "status": "done",
                            "response": {
                                "status": 0,
                                "data": {
                                "relativePath": "",
                                "realPath": ""
                                }
                            },
                            "xhr": {}
                            }
                        ]}
                    >
                        <Upload
                            name="imageFile"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={apiUrl + "/upload/img"}
                            beforeUpload={beforeUploads}
                            onChange={handleChanges}
                            headers={{ "Authorization": "Bearer " + token }}
                        >
                            {imageUrls ? <img src={imageUrls} alt="avatar" style={{ width: '100%' }} /> : uploadButtons}
                        </Upload>
                    </Form.Item>

                    <Form.Item hidden
                        label={"分类Banner"}
                        name={"goodsClassBanner"}
                    >
                        <Input />
                    </Form.Item>
 
                    <Form.Item
                        label={"分类排序"}
                        name={"goodsClassSort"}
                        rules={[{ required: true, message: "分类排序必填" }]}
                    >
                        <Input placeholder={"请输入分类排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"是否显示"}
                        name={"goodsClassShow"}
                        rules={[{ required: true, message: "分类显示必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"显示商品内容"}
                        name={"goodsClassLocal"}
                        rules={[{ required: true, message: "显示商品内容必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>本地</Radio>
                            <Radio value={false}>全国</Radio>
                        </Radio.Group>
                    </Form.Item>
                
                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

