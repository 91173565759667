import { useMutation, useQuery, useQueryClient } from "react-query";
import { Orders } from "../interfaces/orders";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

//订单列表
export const useOrdersList = (param?: Partial<Orders>) => {
    const client = useHttp();
    const result = useQuery<any>(['ordersList', param], () => client('orders', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.ordersVOList;
    return { ...result, totalElements, data };
}

//订单详情
export const useOrders = (ordersId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['orders', { ordersId }],
        () => client(`orders/${ordersId}`),
        {
            enabled: Boolean(ordersId)
        }
    )
}

export const useOrdersMutation = () => {
    const client = useHttp()
    return useMutation(
        ({ ordersId }: { ordersId: number }) =>
            client(`orders/${ordersId}`, {
                method: "GET",
            }
        )
    )
};

//备注
export const useRemarkOrders = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Orders>) => client(`orders/remark`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersList')
        }
    )
}

//所属门店
export const useOrdersStore = (ordersId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['ordersStore', { ordersId }],
        () => client(`orders/store/${ordersId}`),
        {
            enabled: Boolean(ordersId)
        }
    )
}

export const useOrdersStoreMutation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ ordersId }: { ordersId: number}) =>
            client(`orders/store/${ordersId}`, {
            method: "GET",
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersStore')
        }
    );
};

//供应商订单
export const useSupplierOrders = (ordersId?: number, supplierId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['orders', { ordersId }],
        () => client(`orders/supplier?ordersId=${ordersId}&supplierId=${supplierId}`),
        {
            enabled: Boolean(ordersId)
        }
    )
}


