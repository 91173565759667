import { Button, Card, Form, DatePicker, Table, Modal, message } from "antd"
import { ErrorBox } from "../../components/lib"
import { useStoreBillList } from "../../services/store-bill-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreBillSearchParams } from "./util"
import dayjs from 'dayjs';
import { useState } from "react";
import { useAuth } from "../../context/auth-context"
const { RangePicker } = DatePicker;

export const StoreBillListPage = () => {
    useDocumentTitle('账单列表', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const data = useAuth();
    const storeId = data?.storeAccount?.storeId
    const [param, setParam] = useStoreBillSearchParams()
    const { isLoading, error, totalElements, data: list } = useStoreBillList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startStoreBillDate, setStartStoreBillDate] = useState('');
    const [endStoreBillDate, setEndStoreBillDate] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setStartStoreBillDate(dateString[0]);
        setEndStoreBillDate(dateString[1]);
    }
    const [op, setOp] = useState(1)
    function changeOpSubmit() {
        setOp(1)
    }
    function changeOpExport() {
        setOp(2)
    }
    //提交
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                startStoreBillDate: startStoreBillDate,
                endStoreBillDate: endStoreBillDate,
                pageNum: null
            })
        } else if (op === 2) {
            Modal.confirm({
                title: '确定导出当前列表数据吗?',
                content: '点击确定导出',
                okText: '确定',
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href =
                            apiUrl +
                            `/exportStoreBill?startStoreBillDate=${startStoreBillDate}&endStoreBillDate=${endStoreBillDate}&storeId=${storeId}`
                        message.success('导出成功')
                        Modal.destroyAll()
                    }).catch(() => console.log('Oops errors!'))
                },
                cancelText: '取消',
            })
        }
    }
    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="billDetailDateRange" label="对账日期" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={changeOpSubmit} style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                        {/* <Button onClick={changeOpExport} style={{ marginLeft: 20 }} type='primary' htmlType='submit'>
                            导出
                        </Button> */}
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.storeBillId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '日期',
                        dataIndex: 'storeBillDate',
                        key: 'storeBillDate',
                        render(value, storeBill) {
                            return <span>
                                {storeBill.storeBillDate ? dayjs(storeBill.storeBillDate).format('YYYY-MM-DD') : '无'}
                            </span>
                        },
                    },
                    {
                        title: '收入（元）',
                        dataIndex: 'storeBillIncomeMoney',
                        key: 'storeBillIncomeMoney',
                        render(value, storeBill) {
                            return <span>
                                + {storeBill.storeBillIncomeMoney}<br />
                                {storeBill.storeBillIncomeNum}笔
                            </span>
                        },
                    },
                    {
                        title: '支出（元）',
                        dataIndex: 'storeBillPayMoney',
                        key: 'storeBillPayMoney',
                        render(value, storeBill) {
                            return <span>
                                - {storeBill.storeBillPayMoney}<br />
                                {storeBill.storeBillPayNum}笔
                            </span>
                        },
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}