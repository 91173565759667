import { Button, Col, Divider, Form, Input, message, Row} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddGoodsTag } from "../../services/goods-tag-service";
import { useDocumentTitle } from "../../utils"

export const GoodsTagAddPage = () => {
    //标题
    useDocumentTitle('新建商品标签', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //新建
    const useMutateAddGoodsTag = useAddGoodsTag;
    const {mutateAsync, error } =  useMutateAddGoodsTag();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/goods/manage/goods-tag/list"; 
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/manage/goods-tag/list"}>
                        <Button type="primary">标签列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}>
                <Form.Item
                    label={"标签名称"}
                    name={"goodsTagName"}
                    rules={[{ required: true, message: "标签名称必填" }]}
                >
                    <Input placeholder={"请输入标签名称"} />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>  
            </Form>
        </div>
    )
}

