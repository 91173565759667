import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreVideo } from "../interfaces/store-video";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreVideoList = (param?: Partial<StoreVideo>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeVideoList', param], () => client('storeVideo', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreVideo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreVideo>) => client(`storeVideo`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoList')
        }
    )
}

export const useAddStoreVideo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreVideo>) => client(`storeVideo`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoList')
        }
    )
}

export const useDeleteStoreVideo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeVideoId }: { storeVideoId: number}) =>
            client(`storeVideo/${storeVideoId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeVideoList')
        }
    );
};

export const useStoreVideo = (storeVideoId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeVideo', {storeVideoId}],
        () => client(`storeVideo/${storeVideoId}`),
        {
            enabled: Boolean(storeVideoId)
        }
    )
}