import { Typography, Card, Row, Space, Col, Popover } from "antd"
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useStoreDashboard } from '../../services/store-dashboard-service'
import { useState } from "react"
import { useDocumentTitle } from "../../utils"
const { Text } = Typography

export const DashboardPage = () => {
    useDocumentTitle('概况', true)
    const { data: dashboard } = useStoreDashboard()
    const [nowTime, setNowTime] = useState(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString());
    return (
        <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
            <Card style={{ boxShadow: "0 0 10px 3px rgb(0 0 0 / 10%)"}}>
                <Row style={{ fontWeight: 500, fontSize: 16, marginBottom: 24, alignItems: 'center' }}>
                    <Space>
                        实时概况<Text style={{ fontSize: 12 }}>更新时间：{nowTime}</Text>
                    </Space>
                </Row>
                <Row style={{ height: 100, marginBottom: 24, }}>
                    <Col span={6}>
                        <Space direction='vertical' align='start' size={8} style={{ width: '100%', paddingLeft: 20 }}>
                            <Text style={{ color: '#969799' }}>
                                支付订单数
                                <Popover arrowPointAtCenter placement='bottom' content={<p style={{ maxWidth: 300 }}>0点截至当前时间，成功付款的订单数，一个订单对应唯一一个订单号</p>}>
                                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                            </Text>
                            <Text style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.todayOrders != null ? dashboard?.data?.todayOrders : '-'}</Text>
                            <Text style={{ color: '#666' }}>昨日：{dashboard?.data?.yesterdayOrders != null ? dashboard?.data?.yesterdayOrders : '-'}</Text>
                        </Space>
                    </Col>
                    <Col span={6} style={{borderRight: "1px solid rgb(240, 242, 245)"}}>
                        <Space direction='vertical' align='start' size={8} style={{ width: '100%', paddingLeft: 20 }}>
                            <Text style={{ color: '#969799' }}>
                                支付金额(元)
                                <Popover arrowPointAtCenter placement='bottom' content={<p style={{ maxWidth: 300 }}>0点截至当前时间，所有付款订单金额之和</p>}>
                                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                            </Text>
                            <Text style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.todayOrdersAmount != null ? dashboard?.data?.todayOrdersAmount : '-'}</Text>
                            <Text style={{ color: '#666' }}>昨日：{dashboard?.data?.yesterdayOrdersAmount != null ? dashboard?.data?.yesterdayOrdersAmount : '-'}</Text>
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space direction='vertical' align='start' size={8} style={{ width: '100%', paddingLeft: 20 }}>
                            <Text style={{ color: '#969799' }}>
                                支付人数
                                <Popover arrowPointAtCenter placement='bottom' content={<p style={{ maxWidth: 300 }}>0点截至当前时间，下单并且付款成功的客户数，一人多次付款记为一人</p>}>
                                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                            </Text>
                            <Text style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.todayPayMember != null ? dashboard?.data?.todayPayMember : '-'}</Text>
                            <Text style={{ color: '#666' }}>昨日：{dashboard?.data?.yesterdayPayMember != null ? dashboard?.data?.yesterdayPayMember : '-'}</Text>
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space direction='vertical' align='start' size={8} style={{ width: '100%', paddingLeft: 20 }}>
                            <Text style={{ color: '#969799' }}>
                                客单价
                                <Popover arrowPointAtCenter placement='bottom' content={<p style={{ maxWidth: 300 }}>0点截至当前时间，付款金额/付款人数</p>}>
                                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                            </Text>
                            <Text style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.todayAtv != null ? dashboard?.data?.todayAtv : '-'}</Text>
                            <Text style={{ color: '#666' }}>昨日：{dashboard?.data?.yesterdayAtv != null ? dashboard?.data?.yesterdayAtv : '-'}</Text>
                        </Space>
                    </Col>
                </Row>
            </Card>
            <Card style={{ boxShadow: "0 0 10px 3px rgb(0 0 0 / 10%)"}}>
                <Row style={{ fontWeight: 500, fontSize: 16, marginBottom: 24, alignItems: 'center' }}>
                    代办事项
                </Row>
                <Row>
                    <Col span={6}>
                        <Space size={12} direction='vertical' align='center'>
                            <div style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.noPaidOrders != null ? dashboard?.data?.noPaidOrders : '-'}</div>
                            <Text>待付款订单</Text>
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space size={12} direction='vertical' align='center'>
                            <div style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.paidOrders != null ? dashboard?.data?.paidOrders : '-'}</div>
                            <Text>待发货订单</Text>
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space size={12} direction='vertical' align='center'>
                            <div style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.refunding != null ? dashboard?.data?.refunding : '-'}</div>
                            <Text>待售后订单</Text>
                        </Space>   
                    </Col>
                    <Col span={6}>
                        <Space size={12} direction='vertical' align='center'>
                            <div style={{color: "#323233", fontSize: 28, fontWeight: 700}}>{dashboard?.data?.saleOutGoods != null ? dashboard?.data?.saleOutGoods : '-'}</div>
                            <Text>已售罄商品</Text>
                        </Space>  
                    </Col>
                </Row>
            </Card>
        </Space>
    )
}