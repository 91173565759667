import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd";
import { Link, useLocation } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useStorePhotoList, useDeleteStorePhoto, } from "../../services/store-photo-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStorePhotoSearchParams } from "./util";

export const StorePhotoListPage = () => {
  useDocumentTitle("相片列表", true);
  const [param, setParam] = useStorePhotoSearchParams();
  const { isLoading, error, totalElements, data: list, } = useStorePhotoList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStorePhoto = useDeleteStorePhoto;
  const { mutateAsync } = useMutateDeleteStorePhoto();
  const confirmDeleteStorePhoto = (storePhotoId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storePhotoId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["searchHot"]}
      >
        <Menu.Item key="searchHot">
          <Link to={"/store/photo/list"}>相片管理</Link>
        </Menu.Item>
        <Menu.Item key="searchDefault">
          <Link to={"/store/photo/album/list"}>相册管理</Link>
        </Menu.Item>
      </Menu>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/store/photo/add"}>
            <Button type="primary">新建相片</Button>
          </Link>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storePhotoId}
        pagination={paginationProps}
        columns={[
          {
            title: "相片ID",
            dataIndex: "storePhotoId",
            key: "storePhotoId",
            width: 100,
          },
          {
            title: "相片名称",
            dataIndex: "storePhotoName",
            key: "storePhotoName",
          },
          {
            title: "所属相册",
            dataIndex: "storePhotoAlbumName",
            key: "storePhotoAlbumName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storePhoto) => (
              <Space size="middle">
                <Link
                  to={"/store/photo/update/" + storePhoto.storePhotoId}
                >
                  编辑
                </Link>
                <a
                  onClick={() =>
                    confirmDeleteStorePhoto(storePhoto.storePhotoId)
                  }
                >
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
