import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useGoodsSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["goodsId", "goodsSpecOpen","goodsName", "startGoodsAddTime", "endGoodsAddTime", "minGoodsSaleNum", "maxGoodsSaleNum", "minGoodsPrice", "maxGoodsPrice", "goodsClassId", "goodsTagId", "typeId", "storeId", "goodsRecommend", "pageNum"]);
    return [
        useMemo(() => ({ ...param, goodsId: Number(param.goodsId) || undefined, storeId: Number(param.storeId) || undefined, 
            goodsClassId: Number(param.goodsClassId) || undefined, goodsTagId: Number(param.goodsTagId) || undefined, 
            pageNum: Number(param.pageNum) || undefined }), [param]),
        setParam
    ] as const
}