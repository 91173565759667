import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Spin,
  Select,
  Radio,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStoreVideo, useEditStoreVideo } from "../../services/store-video-service";
import { useStoreVideoAlbumListAll } from "../../services/store-video-album-service";
import { useDocumentTitle } from "../../utils";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const StoreVideoUpdatePage = () => {
  //标题
  useDocumentTitle("编辑视频", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const storeVideoId = pathname.match(/store\/video\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingStoreVideo, isLoading } = useStoreVideo(
    Number(storeVideoId)
  );
  //准备专辑列表
  const { data: storeVideoAlbumList } = useStoreVideoAlbumListAll();
  const options = storeVideoAlbumList?.data?.map((item: any, value: any) => (
    <Option value={item.storeVideoAlbumId} key={item.storeVideoAlbumId}>
      {item.storeVideoAlbumName}
    </Option>
  ));
  // 图片
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeVideoCoverPreviewPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //完整音频
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const uploadVideoButton = (
    <div>
      {uploadVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传视频</div>
    </div>
  );
  const beforeVideoUpload = (file: any) => {
    setVideoUrl("");
    setUploadVideoLoading(true);
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      setUploadVideoLoading(false);
      message.error("Image must smaller than 20MB!");
    }
    return isLt20M;
  };
  const handleVideoChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadVideoLoading(false);
      setVideoUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeVideoUrl: relativePath,
      });
    }
  };
  const normVideoFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  useEffect(() => {
    const picUrl = imgUrl  + editingStoreVideo?.data?.storeVideoCoverPreviewPic;
    setImageUrl(picUrl);
    form.setFieldsValue(editingStoreVideo?.data);
    if (editingStoreVideo?.data?.storeVideoUrl) {
      const videoUrl = imgUrl  + editingStoreVideo?.data?.storeVideoUrl;
      setVideoUrl(videoUrl);
    }
  }, [editingStoreVideo, form]);
  //编辑
  const useMutateEditStoreVideo = useEditStoreVideo;
  const { mutateAsync, error } = useMutateEditStoreVideo();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingStoreVideo?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/store/video/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storeVideo"]}
      >
        <Menu.Item key="storeVideo">
          <Link to={"/store/video/list"}>
            视频管理
          </Link>
        </Menu.Item>
        <Menu.Item key="searchDefault">
          <Link to={"/store/video/album/list"}>
            专辑管理
          </Link>
        </Menu.Item>
      </Menu>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/store/video/list"} >
              <Button type="primary">视频列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"视频名称"}
            name={"storeVideoName"}
            rules={[{ required: true, message: "视频名称必填" }]}
          >
            <Input placeholder={"请输入视频名称"} />
          </Form.Item>

          <Form.Item
            label={"所属专辑"}
            name={"storeVideoAlbumId"}
            rules={[{ required: true, message: "所属专辑必选" }]}
          >
            <Form.Item name={"storeVideoAlbumId"} noStyle>
              <Select
                showSearch
                placeholder="请选择所属专辑"
                optionFilterProp="children"
              >
                <Option value={0} key={0}>
                  请选择
                </Option>
                {options}
               </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name={"uploadPic"}
            label={"视频封面"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={{ textAlign: "left" }}
            initialValue={[
              {
              "uid": "",
              "lastModified": "",
              "lastModifiedDate": "",
              "name": "",
              "size": "",
              "type": "",
              "percent": "",
              "originFileObj": {
                  "uid": ""
              },
              "status": "done",
              "response": {
                  "status": 0,
                  "data": {
                  "relativePath": "",
                  "realPath": ""
                  }
              },
              "xhr": {}
              }
            ]}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrl ?  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} /> : uploadButton }
            </Upload>
          </Form.Item>

          <Form.Item
            hidden
            label={"视频封面"}
            name={"storeVideoCoverPreviewPic"}
            rules={[{ required: true, message: "视频封面必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"uploadVideo"}
            label={"视频"}
            valuePropName="fileList"
            getValueFromEvent={normVideoFile}
            style={{ textAlign: "left" }}
            initialValue={[
              {
              "uid": "",
              "lastModified": "",
              "lastModifiedDate": "",
              "name": "",
              "size": "",
              "type": "",
              "percent": "",
              "originFileObj": {
                  "uid": ""
              },
              "status": "done",
              "response": {
                  "status": 0,
                  "data": {
                  "relativePath": "",
                  "realPath": ""
                  }
              },
              "xhr": {}
              }
            ]}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeVideoUpload}
              onChange={handleVideoChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {videoUrl ? <video src={videoUrl} style={{ width: "100%" }} /> : uploadVideoButton }
            </Upload>
          </Form.Item>

          <Form.Item hidden label={"视频"} name={"storeVideoUrl"}>
            <Input />
          </Form.Item>

          <Form.Item
            label={"视频排序"}
            name={"storeVideoSort"}
            rules={[{ required: true, message: "视频排序必填" }]}
          >
            <Input placeholder={"请输入视频排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"storeVideoShow"}
            rules={[{ required: true, message: "视频显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
