import {
  Divider,
  Row,
  Col,
  Button,
  Input,
  Form,
  DatePicker,
  message,
  Space,
  Image,
  Radio,
  Menu,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEditGroupon, useGroupon } from "../../services/groupon-service";
import { useForm } from "antd/lib/form/Form";
import { useDocumentTitle } from "../../utils";
import moment from "moment";
import { useAreaListStore } from "../../services/area-service";
const { RangePicker } = DatePicker;

export const GrouponUpdatePage = () => {
  useDocumentTitle("编辑拼团", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const { pathname } = useLocation();
  const grouponId = pathname.match(
    /application\/groupon-manage\/groupon\/update\/(\d+)/
  )?.[1];
  const { data: editingGroupon, isLoading } = useGroupon(Number(grouponId));
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  //保存
  const useMutateEditGroupon = useEditGroupon;
  const { mutateAsync, error } = useMutateEditGroupon();
  const [val, setVal] = useState([{}]);
  //限购
  const [purchaseLimit, setPurchaseLimit] = useState<any>(false);
  const handlePurchaseLimit = (e: any) => {
    setPurchaseLimit(e.target.value);
  };
  //规格
  const [goodsSpecOpen, setGoodsSpecOpen] = useState(false);
  //状态
  const [grouponState, setGrouponState] = useState(3);
  //活动时间
  const rangeConfig = {
    rules: [{ type: "array" as const, message: "请选择时间!" }],
  };
  const [grouponStartTimeStr, setGrouponStartTimeStr] = useState();
  const [grouponEndTimeStr, setGrouponEndTimeStr] = useState();
  const handleSelectTime = (value: any, dateString: any) => {
    setGrouponStartTimeStr(dateString[0]);
    setGrouponEndTimeStr(dateString[1]);
  };

  //准备区域数据
  const { data: areaList } = useAreaListStore();
  const areaListOptions = areaList?.data?.map((item: any, value: any) => (
    <Select.Option value={item.areaId} key={item.areaId}>
      {item.areaName}
    </Select.Option>
  ));

  //表单
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(editingGroupon?.data);
    setGoodsSpecOpen(editingGroupon?.data?.goodsSpecOpen);
    if (editingGroupon?.data?.goodsSpecOpen) {
      setVal(editingGroupon?.data?.grouponGoodsSkuList);
    }
    setPurchaseLimit(editingGroupon?.data?.grouponPurchaseLimit);
    setGrouponState(editingGroupon?.data?.grouponState);
    if (editingGroupon?.data?.grouponStartTimeStr) {
      setGrouponStartTimeStr(editingGroupon?.data?.grouponStartTimeStr);
    }
    if (editingGroupon?.data?.grouponEndTimeStr) {
      setGrouponEndTimeStr(editingGroupon?.data?.grouponEndTimeStr);
    }
    if (
      editingGroupon?.data?.grouponStartTimeStr &&
      editingGroupon?.data?.grouponEndTimeStr
    ) {
      form.setFieldsValue({
        grouponTimeRange: [
          moment(
            editingGroupon?.data?.grouponStartTimeStr,
            "YYYY-MM-DD HH:mm:ss"
          ),
          moment(
            editingGroupon?.data?.grouponEndTimeStr,
            "YYYY-MM-DD HH:mm:ss"
          ),
        ],
      });
    }
  }, [editingGroupon, form]);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    const grouponGoodsSkuList = values?.grouponGoodsSkuList;
    mutateAsync({
      ...editingGroupon?.data,
      ...values,
      grouponGoodsSkuList,
      grouponStartTimeStr,
      grouponEndTimeStr,
    }).then((data) => {
      if (data.status === 0) {
        window.location.href = "/#/application/groupon-manage/groupon/list";
      } else {
        setSubmitLoading(false);
        message.error(data.msg);
      }
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["groupon"]}
      >
        <Menu.Item key="groupon">
          <Link to={"/application/groupon-manage/groupon/list"}>拼团管理</Link>
        </Menu.Item>
        <Menu.Item key="grouponAdv">
          <Link to={"/application/groupon-manage/groupon/adv/list"}>
            拼团广告
          </Link>
        </Menu.Item>
      </Menu>
      <Row>
        <Col span={12}>
          <Link to={"/application/groupon-manage/groupon/list"}>
            <Button type="primary">拼团列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <div>
        <Form form={form} {...layout} name="basic" onFinish={onFinish}>
          <Form.Item
            label={"活动名称"}
            name={"grouponTitle"}
            rules={[{ required: true, message: "活动名称必填" }]}
          >
            <Input
              placeholder={"请输入活动名称"}
              disabled={grouponState === 3 ? true : false}
            />
          </Form.Item>

          <Form.Item
            name="grouponTimeRange"
            label="活动时间"
            {...rangeConfig}
            rules={[{ required: true, message: "活动时间必选" }]}
          >
            <RangePicker
              onChange={handleSelectTime}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ width: "100%" }}
              disabled={
                grouponState === 2
                  ? [true, false]
                  : grouponState === 3
                  ? [true, true]
                  : [false, false]
              }
            />
          </Form.Item>

          {editingGroupon?.data?.goodsId ? (
            <Form.Item name="grouponGoods" label="已选商品">
              <Row>
                <Col span={6}>
                  <Image
                    width="100%"
                    src={
                      imgUrl +
                      editingGroupon?.data?.goodsPic
                    }
                  />
                </Col>
                <Col span={18} style={{ paddingLeft: 10 }}>
                  <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                      {editingGroupon?.data?.goodsName}
                    </Col>
                    {goodsSpecOpen === false ? (
                      <div>
                        <Col span={24} style={{ marginBottom: 5 }}>
                          规格：{editingGroupon?.data?.goodsSpecName}
                        </Col>
                      </div>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
            </Form.Item>
          ) : (
            ""
          )}

          {goodsSpecOpen === false ? (
            <div>
              <Form.Item label="拼团价设置">
                <Row
                  style={{
                    height: 50,
                    lineHeight: 50,
                    fontWeight: 500,
                    backgroundColor: "#f7f8fa",
                  }}
                >
                  <Col
                    style={{
                      height: 50,
                      lineHeight: "50px",
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    规格
                  </Col>
                  <Col
                    style={{
                      height: 50,
                      lineHeight: "50px",
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                    }}
                  >
                    拼团价格
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col
                    style={{
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <Input
                      readOnly
                      value={editingGroupon?.data?.goodsSpecName}
                      placeholder="请输入规格名称"
                    />
                  </Col>
                  <Col
                    style={{
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                    }}
                  >
                    <Form.Item
                      name={"grouponPrice"}
                      rules={[{ required: true, message: "拼团价格必填" }]}
                    >
                      <Input disabled={grouponState === 1 ? false : true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          {goodsSpecOpen === true ? (
            <div>
              <Form.Item label="拼团价设置">
                <Row
                  style={{
                    height: 50,
                    lineHeight: 50,
                    fontWeight: 500,
                    backgroundColor: "#f7f8fa",
                  }}
                >
                  <Col
                    style={{
                      height: 50,
                      lineHeight: "50px",
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    规格
                  </Col>
                  <Col
                    style={{
                      height: 50,
                      lineHeight: "50px",
                      width: "50%",
                      textAlign: "left",
                      paddingRight: 10,
                    }}
                  >
                    拼团价格
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Form.List initialValue={val} name="grouponGoodsSkuList">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Space>
                              <Form.Item
                                hidden
                                name={[field.name, "grouponGoodsSkuId"]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, "goodsSkuName"]}
                                rules={[
                                  { required: true, message: "规格名称必填" },
                                ]}
                              >
                                <Input readOnly />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, "grouponPrice"]}
                                rules={[
                                  { required: true, message: "拼团价格必填" },
                                ]}
                              >
                                <Input
                                  disabled={grouponState === 1 ? false : true}
                                />
                              </Form.Item>
                            </Space>
                          </Space>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <Form.Item
            label={"成团人数"}
            name={"grouponNum"}
            rules={[{ required: true, message: "成团人数必填" }]}
            style={{ textAlign: "left" }}
          >
            <Input
              placeholder={"请输入成团人数"}
              disabled={grouponState === 1 ? false : true}
            />
          </Form.Item>

          <Form.Item
            label={"成团时长"}
            name={"grouponHour"}
            rules={[{ required: true, message: "成团时长必填" }]}
            style={{ textAlign: "left" }}
          >
            <Input
              placeholder={"请输入成团时长"}
              disabled={grouponState === 1 ? false : true}
            />
          </Form.Item>

          <Form.Item
            label={"每人限购"}
            name={"grouponPurchaseLimit"}
            rules={[{ required: true, message: "每人限购必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group
              onChange={handlePurchaseLimit}
              disabled={grouponState === 1 ? false : true}
            >
              <Radio defaultChecked value={true}>
                开启限购
              </Radio>
              <Radio value={false}>关闭限购</Radio>
            </Radio.Group>
          </Form.Item>

          {purchaseLimit === true ? (
            <div>
              <Form.Item
                label={"限购数量"}
                name={"grouponPurchaseLimitNum"}
                rules={[{ required: true, message: "限购数量必填" }]}
                style={{ textAlign: "left" }}
              >
                <Input
                  placeholder={"请输入限购数量"}
                  disabled={grouponState === 1 ? false : true}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <Form.Item
            label={"排序"}
            name={"grouponSort"}
            rules={[{ required: true, message: "排序必填" }]}
            style={{ textAlign: "left" }}
          >
            <Input
              placeholder={"请输入排序"}
              disabled={grouponState === 3 ? true : false}
            />
          </Form.Item>

          <Form.Item
            label={"是否推荐"}
            name={"grouponRecommend"}
            rules={[{ required: true, message: "是否推荐必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group disabled={grouponState === 3 ? true : false}>
              <Radio value={true}>推荐</Radio>
              <Radio defaultChecked value={false}>
                不推荐
              </Radio>
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            label={"显示区域"}
            name={"areaId"}
            rules={[{ required: true, message: "显示区域必选" }]}
            style={{ textAlign: "left" }}
          >
            <Select
              showSearch
              placeholder="请选择显示区域"
              optionFilterProp="children"
              style={{ textAlign: "left" }}
            >
              <option value={0}>全部</option>
              {areaListOptions}
            </Select>
          </Form.Item> */}

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
