import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useAddStoreGoodsClass,
  useStoreGoodsClassListTree,
} from "../../services/store-goods-class-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const StoreGoodsClassAddPage = () => {
  //标题
  useDocumentTitle("新建菜单", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备树状结构数据
  const { data: storeGoodsClassList } = useStoreGoodsClassListTree();
  const storeGoodsClassListOptions = storeGoodsClassList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.storeGoodsClassId} key={item.storeGoodsClassId}>
        {item.storeGoodsClassName}
      </Option>
    )
  );
  //表单
  const [form] = useForm();
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeGoodsClassPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //图片二
  const [uploadLoadings, setUploadLoadings] = useState(false);
  const [imageUrls, setImageUrls] = useState("");
  const uploadButtons = (
    <div>
      {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUploads = (file: any) => {
    setImageUrls("");
    setUploadLoadings(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoadings(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoadings(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChanges = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoadings(false);
      setImageUrls(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeGoodsClassBanner: relativePath,
      });
    }
  };
  const normFiles = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //新建
  const useMutateAddStoreGoodsClass = useAddStoreGoodsClass;
  const { mutateAsync, error } = useMutateAddStoreGoodsClass();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/goods/class/list";
    });
  };
  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/goods/class/list"}>
            <Button type="primary">分类列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          storeGoodsClassSort: 0,
          storeGoodsClassShow: true,
        }}
      >
        <Form.Item
          label={"上级分类"}
          name={"storeGoodsClassParentId"}
          rules={[{ required: true, message: "上级分类必选" }]}
        >
          <Select
            showSearch
            placeholder="请选择上级分类"
            optionFilterProp="children"
            style={{ textAlign: "left" }}
          >
            <option value={0}>无</option>
            {storeGoodsClassListOptions}
          </Select>
        </Form.Item>

        <Form.Item
          label={"分类名称"}
          name={"storeGoodsClassName"}
          rules={[{ required: true, message: "分类名称必填" }]}
        >
          <Input placeholder={"请输入分类名称"} />
        </Form.Item>

        <Form.Item
          name={"uploadPic"}
          label={"分类图标"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ textAlign: "left" }}
        >
          <Upload
            name="imageFile"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiUrl + "/upload/img"}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{ Authorization: "Bearer " + token }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item hidden label={"分类图标"} name={"storeGoodsClassPic"}>
          <Input />
        </Form.Item>

        <Form.Item
          name={"uploadPics"}
          label={"分类Banner"}
          valuePropName="fileList"
          getValueFromEvent={normFiles}
          style={{ textAlign: "left" }}
        >
          <Upload
            name="imageFile"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiUrl + "/upload/img"}
            beforeUpload={beforeUploads}
            onChange={handleChanges}
            headers={{ Authorization: "Bearer " + token }}
          >
            {imageUrls ? (
              <img src={imageUrls} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButtons
            )}
          </Upload>
        </Form.Item>

        <Form.Item hidden label={"分类Banner"} name={"storeGoodsClassBanner"}>
          <Input />
        </Form.Item>

        <Form.Item
          label={"分类排序"}
          name={"storeGoodsClassSort"}
          rules={[{ required: true, message: "分类排序必填" }]}
        >
          <Input placeholder={"请输入分类排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"storeGoodsClassShow"}
          rules={[{ required: true, message: "分类显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              显示
            </Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
