import { useMutation, useQuery, useQueryClient } from "react-query";
import { ShopAddress } from "../interfaces/shop-address";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useShopAddressList = (param?: Partial<ShopAddress>) => {
    const client = useHttp();
    const result = useQuery<any>(['shopAddressList', param], () => client('shopAddress', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditShopAddress = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ShopAddress>) => client(`shopAddress`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('shopAddressList')
        }
    )
}

export const useAddShopAddress = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ShopAddress>) => client(`shopAddress`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('shopAddressList')
        }
    )
}

export const useDeleteShopAddress = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ shopAddressId }: { shopAddressId: number}) =>
            client(`shopAddress/${shopAddressId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('shopAddressList')
        }
    );
};

export const useShopAddress = (shopAddressId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['shopAddress', {shopAddressId}],
        () => client(`shopAddress/${shopAddressId}`),
        {
            enabled: Boolean(shopAddressId)
        }
    )
}

export const useShopAddressListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["shopAddressListAll", { }], () =>
        client(`shopAddress/listAll`)
    );
    const data = result.data?.data;
    return {...result, data };
};

