import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
  Image,
  Tabs,
  Select,
  Card,
  Form,
  DatePicker,
  Input,
  Menu,
  Dropdown,
  Tooltip,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useGoodsList,
  useDeleteGoods,
  useRecommedGoods,
  useUnRecommedGoods,
  useShareGoods,
  useGoodsGoodsServeTagList,
  useAddGoodsGoodsServeTag,  
  useStoreRecommedGoods,
  useUnStoreRecommedGoods
} from "../../services/goods-service";
import { useGoodsClassListTree } from "../../services/goods-class-service";
import { useGoodsTagListAll } from "../../services/goods-tag-service";
import { useGoodsServeTagListAll } from "../../services/goods-serve-tag-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useGoodsSearchParams } from "./util";
import { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useStoreListAll } from "../../services/store-service";
import { useAuth } from "../../context/auth-context";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import "./list.css";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const GoodsListPage = () => {
  useDocumentTitle("商品列表", true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useGoodsSearchParams();
  console.log("param,page",param.goodsSpecOpen,Boolean(param.goodsSpecOpen),Boolean(true),Boolean(false));
  
  const data = useAuth();
  //获取商品分类
  const { data: goodsClassList } = useGoodsClassListTree();
  const goodsClassListData = goodsClassList?.data;
  const optionsGoodsClass = goodsClassListData?.map((item: any, value: any) => (
    <Option value={item.goodsClassId} key={item.goodsClassId}>
      {item.goodsClassName}
    </Option>
  ));
  //获取商品标签
  const { data: goodsTagList } = useGoodsTagListAll();
  const optionsGoodsTag = goodsTagList?.map((item: any, value: any) => (
    <Option value={item.goodsTagId} key={item.goodsTagId}>
      {item.goodsTagName}
    </Option>
  ));
  //获取商品服务标签
  const { data: goodsServeTagList } = useGoodsServeTagListAll();
  const optionsGoodsServeTag = goodsServeTagList?.map((item: any, value: any) => (
    <Option value={item.goodsServeTagId} key={item.goodsServeTagId}>
      {item.goodsServeTagName}
    </Option>
  ));
  //获取供应商
  const { data: storeList } = useStoreListAll();
  const optionsStore = storeList?.data?.map((item: any, value: any) => (
    <Option value={item.storeId} key={item.storeId}>
      {item.storeName}
    </Option>
  ));
  //商品列表
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useGoodsList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    showSizeChanger: false,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteGoods = useDeleteGoods;
  const { mutateAsync } = useMutateDeleteGoods();
  const confirmDeleteGoods = (goodsId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ goodsId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //推荐事件
  const useMutateRecommedGoods = useRecommedGoods;
  const { mutateAsync: mutateAsyncRecommedGoods } = useMutateRecommedGoods();
  const confirmRecommedGoods = (goodsId: number) => {
    Modal.confirm({
      title: "确定推荐这条数据吗?",
      content: "点击确定推荐",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncRecommedGoods({ goodsId }).then(() => {
            message.info("推荐成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //取消推荐事件
  const useMutateUnRecommedGoods = useUnRecommedGoods;
  const { mutateAsync: mutateAsyncUnRecommedGoods } = useMutateUnRecommedGoods();
  const confirmUnRecommedGoods = (goodsId: number) => {
    Modal.confirm({
      title: "确定取消推荐这条数据吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncUnRecommedGoods({ goodsId }).then(() => {
            message.info("取消成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //商家推荐
  const { mutateAsync: mutateAsyncStoreRecommedGoods } = useStoreRecommedGoods();
  const confirmStoreRecommedGoods = (goodsId: number) => {
    Modal.confirm({
      title: "确定推荐这条数据至商家吗?",
      content: "点击确定推荐",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncStoreRecommedGoods({ goodsId }).then(() => {
            message.info("推荐成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //取消商家推荐
  const { mutateAsync: mutateAsyncUnStoreRecommedGoods } = useUnStoreRecommedGoods();
  const confirmUnStoreRecommedGoods = (goodsId: number) => {
    Modal.confirm({
      title: "确定取消推荐这条数据至商家吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsyncUnStoreRecommedGoods({ goodsId }).then(() => {
            message.info("取消成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //提交表单
  const rangeConfig = {
    rules: [{ type: "array" as const, message: "请选择时间!" }],
  };
  //时间
  const [startGoodsAddTime, setStartGoodsAddTime] = useState("");
  const [endGoodsAddTime, setEndGoodsAddTime] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setStartGoodsAddTime(dateString[0]);
    setEndGoodsAddTime(dateString[1]);
  };
  //销量
  const [minGoodsSaleNum, setMinGoodsSaleNum] = useState("");
  const [maxGoodsSaleNum, setMaxGoodsSaleNum] = useState("");
  const handleMinGoodsSaleNum = (e: any) => {
    setMinGoodsSaleNum(e.target.value);
  };
  const handleMaxGoodsSaleNum = (e: any) => {
    setMaxGoodsSaleNum(e.target.value);
  };
  //价格
  const [minGoodsPrice, setMinGoodsPrice] = useState("");
  const [maxGoodsPrice, setMaxGoodsPrice] = useState("");
  const handleMinGoodsPrice = (e: any) => {
    setMinGoodsPrice(e.target.value);
  };
  const handleMaxGoodsPrice = (e: any) => {
    setMaxGoodsPrice(e.target.value);
  };
  //分类
  const [goodsClassId, setGoodsClassId] = useState("");
  const handleGoodsClass = (value: any) => {
    setGoodsClassId(value);
  };
  //标签
  const [goodsTagId, setGoodsTagId] = useState("");
  const handleGoodsTag = (value: any) => {
    setGoodsTagId(value);
  };
  //供应商
  const [storeId, setStoreId] = useState(Number(param.storeId) || "");
  const handleStore = (value: any) => {
    setStoreId(value);
  };
  //上下架
  const [typeId, setTypeId] = useState<number>(2);
  const handleTab = (key: any, event: any) => {
    setTypeId(key);
    setParam({
      ...param,
      typeId: key,
      pageNum: null,
    });
  };
  const [op, setOp] = useState(1);
  function changeOpSubmit() {
    setOp(1);
  }
  function changeOpExport() {
    setOp(2);
  }
  //提交
  const onFinish = (values: any) => {
    if (op === 1) {
      setParam({
        ...param,
        goodsId: values.goodsId,
        goodsName: values.goodsName,
        startGoodsAddTime: startGoodsAddTime,
        endGoodsAddTime: endGoodsAddTime,
        minGoodsSaleNum: minGoodsSaleNum,
        maxGoodsSaleNum: maxGoodsSaleNum,
        minGoodsPrice: minGoodsPrice,
        maxGoodsPrice: maxGoodsPrice,
        goodsClassId: goodsClassId,
        goodsTagId: goodsTagId,
        typeId: typeId,
        storeId: storeId,
        pageNum: null,
      });
    } else if (op === 2) {
      let goodsId = values.goodsId;
      let goodsName = values.goodsName;
      if (goodsId === undefined) {
        goodsId = "";
      }
      if (goodsName === undefined) {
        goodsName = "";
      }
      Modal.confirm({
        title: "确定导出当前列表数据吗?",
        content: "点击确定导出",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            window.location.href =
              apiUrl +
              `/exportGoods?goodsId=${goodsId}&goodsName=${goodsName}&startGoodsAddTime=${startGoodsAddTime}&endGoodsAddTime=${endGoodsAddTime}
              &minGoodsSaleNum=${minGoodsSaleNum}&maxGoodsSaleNum=${maxGoodsSaleNum}&minGoodsPrice=${minGoodsPrice}&maxGoodsPrice=${maxGoodsPrice}
              &goodsClassId=${goodsClassId}&goodsTagId=${goodsTagId}&typeId=${typeId}&storeId=${storeId}`;
            message.success("导出成功");
            Modal.destroyAll();
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };
  //标签id
  const [goodsServeTagIdList, setGoodsServeTagIdList] = useState<any>([]);
  const handleChangeSetGoodsServeTag = (e: any) => {
    const tagIdList: any[] = [];
    if (e && e.length > 0) {
      e.map((item: number) => tagIdList.push(item));
    }
    setGoodsServeTagIdList(tagIdList);
  }
  //用户
  const [goodsId, setGoodsId] = useState<number>();
  //模态框
  const [isGoodsServeTagModalVisible, setIsGoodsServeTagModalVisible] = useState(false);//弹窗状态
  const [submitGoodsServeTagLoading, setSubmitGoodsServeTagLoading] = useState(false);//按钮状态
  //标签列表
  const useMutateGoodsGoodsServeTagList = useGoodsGoodsServeTagList;
  const { mutateAsync: mutateAsyncGoodsGoodsServeTagList } = useMutateGoodsGoodsServeTagList();
  //保存
  const useMutateAddGoodsGoodsServeTag = useAddGoodsGoodsServeTag
  const { mutateAsync: addGoodsGoodsServeTag } = useMutateAddGoodsGoodsServeTag();
  const handleModalCancel = () => {
    setIsGoodsServeTagModalVisible(false);
  };
  const handleModalOk = () => {
    setSubmitGoodsServeTagLoading(true)
    addGoodsGoodsServeTag({
      goodsId,
      goodsServeTagIdList
    }).then((res:any) => {
      message.info(res.msg)
      setIsGoodsServeTagModalVisible(false);
      setSubmitGoodsServeTagLoading(false);
    });
  }
  const [form] = useForm();
  const showGoodsServeTagModal = (goodsId: number) => {
    setGoodsId(goodsId)
    mutateAsyncGoodsGoodsServeTagList({ goodsId }).then((data: any) => {
      const goodsServeTagData: any = [];
      if (data?.data && data?.data?.length > 0) {
        data?.data?.map((item: any) => goodsServeTagData.push(item.goodsServeTagId));
      }
      form.setFieldsValue({
        "goodsGoodsServeTag": goodsServeTagData
      })
      setGoodsServeTagIdList(goodsServeTagData);
    });
    setIsGoodsServeTagModalVisible(true);
  }
  //菜单（推荐，取消推荐）
  const editMenu: any = (record: any) => {
    return (
      <Menu>
        <Menu.Item>
          <Link to={"/goods/manage/goods-manage/update/" + record.goodsId}>编辑</Link>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => confirmDeleteGoods(record.goodsId)}>删除</a>
        </Menu.Item>
      </Menu>
    );
  };
  const opMenu: any = (record: any) => {
    return (
      <Menu>
        
        <Menu.Item>
              <a onClick={() => showGoodsServeTagModal(record.goodsId)}>
                设置标签
              </a>
            </Menu.Item>
        {record.goodsRecommend ? (
          <div>
            <Menu.Item>
              <a onClick={() => confirmUnRecommedGoods(record.goodsId)}>
                取消推荐
              </a>
            </Menu.Item>
          </div>
        ) : (
          <div>
            <Menu.Item>
              <a onClick={() => confirmRecommedGoods(record.goodsId)}>推荐</a>
            </Menu.Item>
          </div>
        )}
        {record.goodsStoreRecommend ? (
          <div>
            <Menu.Item>
              <a onClick={() => confirmUnStoreRecommedGoods(record.goodsId)}>
                取消商家推荐
              </a>
            </Menu.Item>
          </div>
        ) : (
          <div>
            <Menu.Item>
              <a onClick={() => confirmStoreRecommedGoods(record.goodsId)}>商家推荐</a>
            </Menu.Item>
          </div>
        )}
        <Menu.Item>
              <div onClick={() => onExtensionBol(record.goodsId)}>推广</div>
        </Menu.Item>
      </Menu>
    );
  };
  //推广弹窗
  const [modalLoading, setModalLoading] = useState(false);
  const useMutateShareGoods = useShareGoods;
  const { mutateAsync: mutateAsyncShareGoods } = useMutateShareGoods();
  const [extensionBol, setExtensionBol] = useState(false);
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const onExtensionBol = (goodsId: number) => {
    setModalLoading(true);
    mutateAsyncShareGoods({ goodsId }).then((data: any) => {
      setQrcodeUrl(
        imgUrl + "qr/" + data?.data?.goodsQrcode
      );
      setLinkUrl(data?.data?.goodsShortLink);
      setModalLoading(false);
    });
    setExtensionBol(true);
  };
  const handleExtensionBol = () => {
    setExtensionBol(false);
  };
  //   点击复制
  async function onCopy() {
    try {
      navigator.clipboard.writeText(linkUrl);
      message.success("复制成功");
    } catch (err: any) {
      message.error("复制失败:", err);
    }
  }
  return (
    <div>
      <Modal
        confirmLoading={submitGoodsServeTagLoading}
        centered
        bodyStyle={{ height: '100', overflowY: 'auto' }}
        destroyOnClose={true}
        width={550}
        title="设置标签"
        visible={isGoodsServeTagModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form}>
          <Form.Item
            label={"商品服务标签"}
            name={'goodsGoodsServeTag'}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              onChange={handleChangeSetGoodsServeTag}
              placeholder='请选择商品服务标签'
              optionFilterProp='children'>
              {optionsGoodsServeTag}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/goods/manage/goods-manage/add"}>
            <Button type="primary">新建商品</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}></Col>
      </Row>
      <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="goodsAddTimeRange"
                label="创建时间"
                {...rangeConfig}
              >
                <RangePicker
                  onChange={handleSelectTime}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="storeId" label="门店">
                <Select
                  allowClear
                  defaultValue={param.storeId}
                  showSearch
                  placeholder="请选择门店"
                  optionFilterProp="children"
                  onChange={handleStore}
                >
                  {optionsStore}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="goodsName" label="商品名称">
                <Input
                  defaultValue={param.goodsName}
                  value={param.goodsName}
                  placeholder="输入商品名称"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="goodsId" label="商品ID">
                <Input
                  defaultValue={param.goodsId}
                  value={param.goodsId}
                  placeholder="输入商品ID"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="goodsClassId" label="商品分类">
                <Select
                allowClear
                  showSearch
                  placeholder="请选择商品分类"
                  optionFilterProp="children"
                  onChange={handleGoodsClass}
                >
                  {optionsGoodsClass}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label="商品销量">
                <Input.Group compact>
                  <Input
                    onChange={handleMinGoodsSaleNum}
                    name="minGoodsSaleNum"
                    style={{ width: "44%", textAlign: "center" }}
                  />
                  <Input
                    className="site-input-split"
                    style={{
                      width: "12%",
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: "none",
                    }}
                    placeholder="~"
                    disabled
                  />
                  <Input
                    onChange={handleMaxGoodsSaleNum}
                    name="maxGoodsSaleNum"
                    className="site-input-right"
                    style={{ width: "44%", textAlign: "center" }}
                  />
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="goodsPrice" label="商品价格">
                <Input.Group compact>
                  <Input
                    onChange={handleMinGoodsPrice}
                    name="minGoodsPrice"
                    style={{ width: "44%", textAlign: "center" }}
                  />
                  <Input
                    className="site-input-split"
                    style={{
                      width: "12%",
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: "none",
                    }}
                    placeholder="~"
                    disabled
                  />
                  <Input
                    onChange={handleMaxGoodsPrice}
                    name="maxGoodsPrice"
                    className="site-input-right"
                    style={{ width: "44%", textAlign: "center" }}
                  />
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="goodsTagId" label="商品标签">
                <Select
                allowClear
                  showSearch
                  placeholder="请选择商品标签"
                  optionFilterProp="children"
                  onChange={handleGoodsTag}
                >
                  {optionsGoodsTag}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              onClick={changeOpSubmit}
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
            {/* <Button
              onClick={changeOpExport}
              style={{ marginLeft: 20 }}
              type="primary"
              htmlType="submit"
            >
              导出
            </Button> */}
          </Form.Item>
        </Form>
      </Card>
      <Tabs
        onTabClick={handleTab}
        defaultActiveKey="2"
        type="card"
        size={"small"}
      >
        <TabPane tab="全部" key="1"></TabPane>
        <TabPane tab="销售中" key="2"></TabPane>
        <TabPane tab="已售罄" key="3"></TabPane>
        <TabPane tab="仓库中" key="4"></TabPane>
        <TabPane tab="已删除" key="5"></TabPane>
      </Tabs>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.goodsId}
        pagination={paginationProps}
        columns={[
          {
            title: "商品ID",
            dataIndex: "goodsId",
            key: "goodsId",
            width: 80,
            fixed: "left"
          },
          {
            title: "商品名称",
            width: 300,
            dataIndex: "goodsName",
            key: "goodsName",
            fixed: "left",
            render: (value, goods) => (
              <Space size="middle">
                <Row>
                  <Col span={6}>
                    <Image
                      width="100%" 
                      src={imgUrl +  goods.goodsPic}
                    />
                  </Col>
                  <Col span={18} style={{ paddingLeft: 10 }}>
                    {goods.goodsName}
                  </Col>
                </Row>
              </Space>
            ),
          },
          {
            title: "价格（元）",
            dataIndex: "goodsPrice",
            key: "goodsPrice",
          },
          {
            title: "访问量",
            dataIndex: "goodsPageView",
            key: "goodsPageView",
          },
          {
            title: "库存",
            dataIndex: "goodsStorage",
            key: "goodsStorage",
          },
          {
            title: "销量",
            dataIndex: "goodsSaleNum",
            key: "goodsSaleNum",
          },
          {
            title: "创建时间",
            dataIndex: "goodsAddTime",
            key: "goodsAddTime",
            width: 250,
            render(value, goods) {
              return (
                <span>
                  {goods.goodsAddTime
                    ? dayjs(goods.goodsAddTime).format("YYYY-MM-DD HH:mm:ss")
                    : "无"}
                </span>
              );
            },
          },
          {
            title: "门店",
            dataIndex: "storeName",
            key: "storeName",
            width: 250,
          },
          {
            title: "地区",
            dataIndex: "cityName",
            key: "cityName",
          },
          {
            title: "操作",
            key: "action",
            fixed: "right",
            render(value, goods) {
              return (
                <span>
                  {typeId < 5 ? <Space size="middle">
                    <Dropdown overlay={opMenu.bind(this, goods)}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        操作 <DownOutlined />
                      </a>
                    </Dropdown>
                    <Dropdown overlay={editMenu.bind(this, goods)}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        编辑 <DownOutlined />
                      </a>
                    </Dropdown>
                  </Space> : ""}
                </span>
              );
            },
            width: 150,
          },
        ]}

        
        dataSource={list || []}
        scroll={{ x: 1500 }}
      />
      <ErrorBox error={error} />
      <Modal
        title="推广"
        visible={extensionBol}
        width="800px"
        footer={null}
        onCancel={handleExtensionBol}
      >
        <Spin spinning={modalLoading}>
          <div className="zent-tabs">
            <div className="zent-tabs-nav">
              <div className="zent-tabs-nav-content">
                <div className="zent-tabs-scroll">
                  <div className="zent-tabs-tab color-bg">
                    直接购买
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="扫码后直接访问商品"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Tooltip>
                  </div>
                  <div className="zent-tabs-divide"></div>
                  <div
                    style={{ display: "none" }}
                    className="zent-tabs-tab _2-g0"
                  >
                    推广设置
                  </div>
                </div>
              </div>
            </div>
            <div className="_1xhH">
              {qrcodeUrl ? (
                <Image src={qrcodeUrl} width={180} height={180}></Image>
              ) : (
                ""
              )}
            </div>
            <div style={{ width: "313px" }}>
              <p style={{ marginBottom: 12 }}>分享链接：</p>
              <div style={{ display: "flex" }}>
                <Input width={245} disabled value={linkUrl}></Input>
                <Button type="primary" onClick={onCopy}>
                  复制
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
