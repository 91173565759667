import { Button, Col, message, Modal, Row, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useStoreAdvList,
  useDeleteStoreAdv,
} from "../../services/store-adv-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreAdvSearchParams } from "./util";

export const StoreAdvListPage = () => {
  useDocumentTitle("广告列表", true);
  const [param, setParam] = useStoreAdvSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useStoreAdvList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreAdv = useDeleteStoreAdv;
  const { mutateAsync } = useMutateDeleteStoreAdv();
  const confirmDeleteStoreAdv = (storeAdvId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storeAdvId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/store/adv/add"}>
            <Button type="primary">新建广告</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索广告标题"
            style={{ width: 200 }}
            value={param.storeAdvTitle}
            onChange={(evt) =>
              setParam({
                ...param,
                storeAdvTitle: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                storeAdvTitle: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storeadvId}
        pagination={paginationProps}
        columns={[
          {
            title: "广告ID",
            dataIndex: "storeAdvId",
            key: "storeAdvId",
            width: 100,
          },
          {
            title: "广告标题",
            dataIndex: "storeAdvTitle",
            key: "storeAdvTitle",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeAdv) => (
              <Space size="middle">
                <Link to={"/store/adv/update/" + storeAdv.storeAdvId}>编辑</Link>
                <a onClick={() => confirmDeleteStoreAdv(storeAdv.storeAdvId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
