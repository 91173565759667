import { useMutation, useQuery, useQueryClient } from "react-query";
import { StorePhoto } from "../interfaces/store-photo";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStorePhotoList = (param?: Partial<StorePhoto>) => {
    const client = useHttp();
    const result = useQuery<any>(['storePhotoList', param], () => client('storePhoto', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStorePhoto = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StorePhoto>) => client(`storePhoto`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoList')
        }
    )
}

export const useAddStorePhoto = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StorePhoto>) => client(`storePhoto`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoList')
        }
    )
}

export const useDeleteStorePhoto = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storePhotoId }: { storePhotoId: number}) =>
            client(`storePhoto/${storePhotoId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoList')
        }
    );
};

export const useStorePhoto = (storePhotoId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storePhoto', {storePhotoId}],
        () => client(`storePhoto/${storePhotoId}`),
        {
            enabled: Boolean(storePhotoId)
        }
    )
}