import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Spin,
  Radio
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStorePhotoAlbum, useEditStorePhotoAlbum } from "../../services/store-photo-album-service";
import { useDocumentTitle } from "../../utils";

export const StorePhotoAlbumUpdatePage = () => {
  //标题
  useDocumentTitle("编辑相册", true);
  const { pathname } = useLocation();
  const storePhotoAlbumId = pathname.match(/store\/photo\/album\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingStorePhotoAlbum, isLoading } = useStorePhotoAlbum(
    Number(storePhotoAlbumId)
  );
  useEffect(() => {
    form.setFieldsValue(editingStorePhotoAlbum?.data);
  }, [editingStorePhotoAlbum, form]);
  //编辑
  const useMutateEditStorePhotoAlbum = useEditStorePhotoAlbum;
  const { mutateAsync, error } = useMutateEditStorePhotoAlbum();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingStorePhotoAlbum?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/store/photo/album/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storePhotoAlbum"]}
      >
        <Menu.Item key="storePhoto">
          <Link to={"/store/photo/list"}>
            相片管理
          </Link>
        </Menu.Item>
        <Menu.Item key="storePhotoAlbum">
          <Link to={"/store/photo/album/list"}>
            相册管理
          </Link>
        </Menu.Item>
      </Menu>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/store/photo/album/list"}>
              <Button type="primary">相册列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"相册名称"}
            name={"storePhotoAlbumName"}
            rules={[{ required: true, message: "相册名称必填" }]}
          >
            <Input placeholder={"请输入相册名称"} />
          </Form.Item>

          <Form.Item
            label={"相册排序"}
            name={"storePhotoAlbumSort"}
            rules={[{ required: true, message: "相册排序必填" }]}
          >
            <Input placeholder={"请输入相册排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"storePhotoAlbumShow"}
            rules={[{ required: true, message: "相册显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
