import { Divider, Row, Col, Button, Input, Form, DatePicker, message, Space, Image, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEditSeckill, useSeckill } from '../../services/seckill-service';
import { useForm } from 'antd/lib/form/Form';
import { useDocumentTitle } from '../../utils';
import moment from 'moment'
import { useAreaListStore } from '../../services/area-service';
const { RangePicker } = DatePicker;

export const SeckillUpdatePage = () => {
    useDocumentTitle('编辑秒杀', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const { pathname } = useLocation();
    const seckillId = pathname.match(/application\/seckill-manage\/seckill\/update\/(\d+)/)?.[1]
    const { data: editingSeckill, isLoading } = useSeckill(
        Number(seckillId)
    );
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //保存
    const useMutateEditSeckill = useEditSeckill;
    const { mutateAsync, error } = useMutateEditSeckill();
    const [val, setVal] = useState([{}]);
    //限购
    const [purchaseLimit, setPurchaseLimit] = useState<any>(false);
    const handlePurchaseLimit = (e: any) => {
        setPurchaseLimit(e.target.value);
    };
    //规格
    const [goodsSpecOpen, setGoodsSpecOpen] = useState(false);
    //状态
    const [seckillState, setSeckillState] = useState(3);
    //活动时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [seckillStartTimeStr, setSeckillStartTimeStr] = useState();
    const [seckillEndTimeStr, setSeckillEndTimeStr] = useState();
    const handleSelectTime = (value: any, dateString: any) => {
        console.log(dateString)
        setSeckillStartTimeStr(dateString[0]);
        setSeckillEndTimeStr(dateString[1]);
    }
    //表单
    //准备区域数据
    const { data: areaList } = useAreaListStore();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => 
        <Select.Option value={item.areaId} key={item.areaId}>{item.areaName}</Select.Option>
    );
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingSeckill?.data);
        setGoodsSpecOpen(editingSeckill?.data?.goodsSpecOpen);
        if (editingSeckill?.data?.goodsSpecOpen) {
            setVal(editingSeckill?.data?.seckillGoodsSkuList);
        }
        setPurchaseLimit(editingSeckill?.data?.seckillPurchaseLimit);
        setSeckillState(editingSeckill?.data?.seckillState);
        if (editingSeckill?.data?.seckillStartTimeStr) {
            setSeckillStartTimeStr(editingSeckill?.data?.seckillStartTimeStr);
        }
        if (editingSeckill?.data?.seckillEndTimeStr) {
            setSeckillEndTimeStr(editingSeckill?.data?.seckillEndTimeStr);
        }
        if (editingSeckill?.data?.seckillStartTimeStr && editingSeckill?.data?.seckillEndTimeStr) {
            form.setFieldsValue({
                "seckillTimeRange": [moment(editingSeckill?.data?.seckillStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingSeckill?.data?.seckillEndTimeStr, "YYYY-MM-DD HH:mm:ss")]
            })
        }
    }, [editingSeckill, form]);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        const seckillGoodsSkuList = values?.seckillGoodsSkuList
        mutateAsync({ ...editingSeckill?.data, ...values, seckillGoodsSkuList, seckillStartTimeStr, seckillEndTimeStr }).then((data) => {
            if (data.status === 0) {
                window.location.href = "/#/application/seckill-manage/seckill/list";
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/application/seckill-manage/seckill/list"}>
                        <Button type="primary">秒杀列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"活动名称"}
                        name={"seckillTitle"}
                        rules={[{ required: true, message: "活动名称必填" }]}
                    >
                        <Input placeholder={"请输入活动名称"} disabled={seckillState === 3 ? true : false} />
                    </Form.Item>

                    <Form.Item
                        name="seckillTimeRange"
                        label="活动时间"
                        {...rangeConfig}
                        rules={[{ required: true, message: "活动时间必选" }]}
                    >
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }}
                            disabled={seckillState === 2 ? [true, false] : seckillState === 3 ? [true, true] : [false, false]} />
                    </Form.Item>

                    {editingSeckill?.data?.goodsId ? (
                        <Form.Item
                            name="seckillGoods"
                            label="已选商品"
                        >
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl  + editingSeckill?.data?.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{ paddingLeft: 10 }}>
                                    <Row>
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                            {editingSeckill?.data?.goodsName}
                                        </Col>
                                        {goodsSpecOpen === false ?
                                            <div>
                                                <Col span={24} style={{ marginBottom: 5 }}>
                                                    规格：{editingSeckill?.data?.goodsSpecName}
                                                </Col>
                                            </div> : ''
                                        }

                                    </Row>
                                </Col>
                            </Row>
                        </Form.Item>
                    ) : ('')}

                    {goodsSpecOpen === false ?
                        <div>
                            <Form.Item
                                label="秒杀价设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left', paddingRight: 10 }}>
                                        秒杀价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left' }}>
                                        秒杀库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col style={{ width: "33%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        <Input readOnly value={editingSeckill?.data?.goodsSpecName} placeholder="请输入规格名称" />
                                    </Col>
                                    <Col style={{ width: "33%", textAlign: 'left', paddingRight: 10 }}>
                                        <Form.Item
                                            name={"seckillPrice"}
                                            rules={[{ required: true, message: "秒杀价格必填" }]}
                                        >
                                            <Input disabled={seckillState === 1 ? false : true} />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "33%", textAlign: 'left' }}>
                                        <Form.Item
                                            name={"seckillStorage"}
                                            rules={[{ required: true, message: "秒杀库存必填" }]}
                                        >
                                            <Input disabled={seckillState === 1 ? false : true} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div> : ""}

                    {goodsSpecOpen === true ?
                        <div>
                            <Form.Item
                                label="秒杀价设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left', paddingRight: 10 }}>
                                        秒杀价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "33%", textAlign: 'left' }}>
                                        秒杀库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Form.List initialValue={val} name="seckillGoodsSkuList">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Space
                                                        key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Space>
                                                            <Form.Item hidden
                                                                name={[field.name, 'seckillGoodsSkuId']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'goodsSkuName']}
                                                                rules={[{ required: true, message: '规格名称必填' }]}
                                                            >
                                                                <Input readOnly />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'seckillPrice']}
                                                                rules={[{ required: true, message: '秒杀价格必填' }]}
                                                            >
                                                                <Input disabled={seckillState === 1 ? false : true} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'seckillStorage']}
                                                                rules={[{ required: true, message: '秒杀库存必填' }]}
                                                            >
                                                                <Input disabled={seckillState === 1 ? false : true} />
                                                            </Form.Item>
                                                        </Space>
                                                    </Space>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                            </Form.Item>
                        </div> : ""}

                    <Form.Item
                        label={"每人限购"}
                        name={"seckillPurchaseLimit"}
                        rules={[{ required: true, message: "每人限购必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group onChange={handlePurchaseLimit} >
                            <Radio defaultChecked value={true} disabled={seckillState === 1 ? false : true}>开启限购</Radio>
                            <Radio value={false} disabled={seckillState === 1 ? false : true}>关闭限购</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {purchaseLimit === true ?
                        <div>
                            <Form.Item
                                label={"限购数量"}
                                name={"seckillPurchaseLimitNum"}
                                rules={[{ required: true, message: "限购数量必填" }]}
                                style={{ textAlign: 'left' }}
                            >
                                <Input placeholder={"请输入限购数量"} disabled={seckillState === 1 ? false : true} />
                            </Form.Item>
                        </div> : ""}


                    <Form.Item
                        label={"订单取消(分钟)"}
                        name={"seckillOrdersCancelTime"}
                        rules={[{ required: true, message: "订单取消必填" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input placeholder={"买家X分钟未支付订单，订单取消"} disabled={seckillState === 1 ? false : true} />
                    </Form.Item>

                    <Form.Item
                        label={"排序"}
                        name={"seckillSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input placeholder={"请输入排序"} disabled={seckillState === 3 ? true : false} />
                    </Form.Item>

                    <Form.Item
                        label={"是否推荐"}
                        name={"seckillRecommend"}
                        rules={[{ required: true, message: "是否推荐必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true} disabled={seckillState === 3 ? true : false}>推荐</Radio>
                            <Radio defaultChecked value={false} disabled={seckillState === 3 ? true : false}>不推荐</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* <Form.Item
                    label={"显示区域"}
                    name={"areaId"}
                    rules={[{ required: true, message: "显示区域必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择显示区域"
                        optionFilterProp="children"
                        style={{textAlign:'left'}}
                    >
                        <option value={0}>全部</option>
                        {areaListOptions}
                    </Select>
                </Form.Item> */}
                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}