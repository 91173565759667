import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersParcel } from "../interfaces/orders-parcel";

//列表
export const useOrdersParcelList = (ordersId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersParcelList"], () =>
        client("ordersParcel?ordersId=" + ordersId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};

//发货
export const useAddOrdersParcel = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersParcel>) => client(`ordersParcel/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersList')
        }
    )
}

//发货 - 详情页
export const useAddOrdersParcelOnGoodsDetail = () => {
    const client = useHttp()
    return useMutation(
        (params: Partial<OrdersParcel>) => client(`ordersParcel/verification`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => {}
        }
    )
}

export const useSupplierOrdersParcelList = (ordersId?: number, supplierId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersParcelList"], () =>
        client("ordersParcel/supplier?ordersId=" + ordersId + "&supplierId=" + supplierId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};

//核销
export const useVerificationOrdersParcel = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersParcel>) => client(`ordersParcel/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersList')
        }
    )
}