import { Divider, Row, Col, Button, Input, Form, Radio, Spin, message, Menu } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddStoreSettleAccount } from '../../services/store-settle-account-service';
import { useForm } from 'antd/lib/form/Form';

export const StoreSettleAccountAddPage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //保存
    const useMutateStoreSettleAccount = useAddStoreSettleAccount;
    const { mutateAsync, error } = useMutateStoreSettleAccount();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
            window.location.href = "/#/asset/storeWithdrawCash/storeSettleAccount/list";
        });
    };
    return (
        <div>
            <Menu selectedKeys={["storeSettleAccount"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storeWithdrawCashAdd">
                    <Link to='/asset/storeWithdrawCash/add'>提现</Link>
                </Menu.Item>
                <Menu.Item key="storeWithdrawCashList">
                    <Link to='/asset/storeWithdrawCash/list'>提现记录</Link>
                </Menu.Item>
                <Menu.Item key="storeSettleAccount">
                    <Link to='/asset/storeWithdrawCash/storeSettleAccount/list'>银行卡</Link>
                </Menu.Item>
            </Menu>
            <Row>
                <Col span={12}>
                    <Link to={"/asset/storeWithdrawCash/storeSettleAccount/list"}>
                        <Button type="primary">银行卡列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div className="site-layout-background">
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        storeSettleAccountType: 1,
                        storeSettleAccountDefault: true
                    }}
                >
                    <Form.Item
                        label={"账号类型"}
                        name={"storeSettleAccountType"}
                        rules={[{ required: true, message: "账号类型必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={1}>银行卡</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"持卡人"}
                        name={"storeSettleAccountBankEncTrueName"}
                        rules={[{ required: true, message: "持卡人必填" }]}
                    >
                        <Input placeholder={"请输入持卡人"} />
                    </Form.Item>

                    <Form.Item
                        label={"卡号"}
                        name={"storeSettleAccountBankEncBankNo"}
                        rules={[{ required: true, message: "卡号必填" }]}
                    >
                        <Input placeholder={"请输入卡号"} />
                    </Form.Item>

                    <Form.Item
                        label={"所属银行"}
                        name={"storeSettleAccountBankName"}
                        rules={[{ required: true, message: "所属银行必填" }]}
                    >
                        <Input placeholder={"请输入所属银行"} />
                    </Form.Item>

                    <Form.Item
                        label={"默认账号"}
                        name={"storeSettleAccountDefault"}
                        rules={[{ required: true, message: "默认账号必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}