import { useMutation, useQuery, useQueryClient } from "react-query";
import { StorePhotoAlbum } from "../interfaces/store-photo-album";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStorePhotoAlbumList = (param?: Partial<StorePhotoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(['storePhotoAlbumList', param], () => client('storePhotoAlbum', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStorePhotoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StorePhotoAlbum>) => client(`storePhotoAlbum`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoAlbumList')
        }
    )
}

export const useAddStorePhotoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StorePhotoAlbum>) => client(`storePhotoAlbum`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoAlbumList')
        }
    )
}

export const useDeleteStorePhotoAlbum = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storePhotoAlbumId }: { storePhotoAlbumId: number}) =>
            client(`storePhotoAlbum/${storePhotoAlbumId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storePhotoAlbumList')
        }
    );
};

export const useStorePhotoAlbum = (storePhotoAlbumId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storePhotoAlbum', {storePhotoAlbumId}],
        () => client(`storePhotoAlbum/${storePhotoAlbumId}`),
        {
            enabled: Boolean(storePhotoAlbumId)
        }
    )
}

export const useStorePhotoAlbumListAll = () => {
    const client = useHttp()
    return useQuery<any>(
        ['storePhotoAlbumListAll', {}],
        () => client(`storePhotoAlbum/listAll`)
    )
}