import { Button, Card, Form, DatePicker, Table, message, Modal } from "antd"
import { ErrorBox } from "../../components/lib"
import { useStoreBillDetailList } from "../../services/store-bill-detail-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreBillDetailSearchParams } from "./util"
import dayjs from 'dayjs';
import { useState } from "react";
import { useAuth } from "../../context/auth-context"
const { RangePicker } = DatePicker;

export const StoreBillDetailListPage = () => {
    useDocumentTitle('账单明细列表', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const data = useAuth();
    const storeId = data?.storeAccount?.storeId
    const [param, setParam] = useStoreBillDetailSearchParams()
    const { isLoading, error, totalElements, data: list } = useStoreBillDetailList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startStoreBillDetailDate, setStartStoreBillDetailDate] = useState('');
    const [endStoreBillDetailDate, setEndStoreBillDetailDate] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setStartStoreBillDetailDate(dateString[0]);
        setEndStoreBillDetailDate(dateString[1]);
    }
    const [op, setOp] = useState(1)
    function changeOpSubmit() {
        setOp(1)
    }
    function changeOpExport() {
        setOp(2)
    }
    //提交
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                startStoreBillDetailDate: startStoreBillDetailDate,
                endStoreBillDetailDate: endStoreBillDetailDate,
                pageNum: null
            })
        } else if (op === 2) {
            Modal.confirm({
                title: '确定导出当前列表数据吗?',
                content: '点击确定导出',
                okText: '确定',
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href =
                            apiUrl +
                            `/exportStoreBillDetail?startStoreBillDetailDate=${startStoreBillDetailDate}&endStoreBillDetailDate=${endStoreBillDetailDate}&storeId=${storeId}`
                        message.success('导出成功')
                        Modal.destroyAll()
                    }).catch(() => console.log('Oops errors!'))
                },
                cancelText: '取消',
            })
        }
    }
    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="billDetailDateRange" label="入账时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={changeOpSubmit} style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                        {/* <Button onClick={changeOpExport} style={{ marginLeft: 20 }} type='primary' htmlType='submit'>
                            导出
                        </Button> */}
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.storeBillDetailId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '入账时间',
                        dataIndex: 'storeBillDetailDate',
                        key: 'storeBillDetailDate',
                        render(value, storeBillDetail) {
                            return <span>
                                {storeBillDetail.storeBillDetailDate ? dayjs(storeBillDetail.storeBillDetailDate).format('YYYY-MM-DD HH:mm:ss') : '无'}
                            </span>
                        },
                    },
                    {
                        title: '业务单号 | 支付流水号',
                        dataIndex: 'storeBillDetailOrdersSn',
                        key: 'storeBillDetailOrdersSn',
                        render(value, storeBillDetail) {
                            return <span>
                                {storeBillDetail.storeBillDetailOrdersSn}<br />
                                {storeBillDetail.storeBillDetailThirdSn}
                            </span>
                        },
                    },
                    {
                        title: '收支类型',
                        dataIndex: 'storeBillDetailType',
                        key: 'storeBillDetailType',
                        render(value, storeBillDetail) {
                            return <span>
                                {storeBillDetail.storeBillDetailType === 1 ? '收入' : '支出'}
                            </span>
                        },
                    },
                    {
                        title: '金额',
                        dataIndex: 'storeBillDetailMoney',
                        key: 'storeBillDetailMoney',
                        render(value, storeBillDetail) {
                            return <span>
                                {storeBillDetail.storeBillDetailType === 1 ? '+' : '-'} {storeBillDetail.storeBillDetailMoney}
                            </span>
                        },
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}