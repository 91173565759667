import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreGoodsClass } from "../interfaces/store-goods-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreGoodsClassList = (param?: Partial<StoreGoodsClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeGoodsClassList', param], () => client('storeGoodsClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.storeGoodsClassVOList;
    return {...result, totalElements, data };
}

export const useEditStoreGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreGoodsClass>) => client(`storeGoodsClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeGoodsClassList')
        }
    )
}

export const useAddStoreGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreGoodsClass>) => client(`storeGoodsClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeGoodsClassList')
        }
    )
}

export const useDeleteStoreGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeGoodsClassId }: { storeGoodsClassId: number}) =>
            client(`storeGoodsClass/${storeGoodsClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeGoodsClassList')
        }
    );
};

export const useStoreGoodsClass = (storeGoodsClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeGoodsClass', {storeGoodsClassId}],
        () => client(`storeGoodsClass/${storeGoodsClassId}`),
        {
            enabled: Boolean(storeGoodsClassId)
        }
    )
}

export const useStoreGoodsClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(['storeGoodsClassListTree', {}], () => client('storeGoodsClass/listTree'))
}
