import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAddGoodsSpecValue } from "../../services/goods-spec-value-service";
import { useDocumentTitle } from "../../utils"

export const GoodsSpecValueAddPage = () => {
    //标题
    useDocumentTitle('新建商品规格值', true)
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/goods\/manage\/goods-spec\/value\/add\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //新建
    const useMutateAddGoodsSpecValue = useAddGoodsSpecValue;
    const {mutateAsync, error } =  useMutateAddGoodsSpecValue();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, goodsSpecId }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/goods/manage/goods-spec/value/list/" + goodsSpecId; 
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/manage/goods-spec/value/list/" + goodsSpecId}>
                        <Button type="primary">商品规格值列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    goodsSpecValueSort: 0
                }}>
                <Form.Item
                    label={"规格值名称"}
                    name={"goodsSpecValueName"}
                    rules={[{ required: true, message: "规格值名称必填" }]}
                >
                    <Input placeholder={"请输入规格值名称"} />
                </Form.Item>

                <Form.Item
                    label={"规格值排序"}
                    name={"goodsSpecValueSort"}
                    rules={[{ required: true, message: "规格值排序必填" }]}
                >
                    <Input placeholder={"请输入规格值排序"} />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>  
            </Form>
        </div>
    )
}

