import { useQuery } from "react-query";
import { StoreBill } from "../interfaces/store-bill";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreBillList = (param?: Partial<StoreBill>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeBillList', param], () => client('storeBill', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}